import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';

// Column
import {
  GlobalCropsColumns,
  GlobalCropsViewColumns,
} from '../../appconfig/DatatableSetting';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Loader } from '../../components/Loader/Loader';
import { globalPackingSizeValidate } from '../../config/Validate';

export const GlobalCrops = () => {
  document.title = 'CropWise Stock  | Swarnim Farms';

  //Navigate Another Route
  const navigate = useNavigate();

  //Crops Crud Object
  const [cropsList, setCropsList] = useState<any>([]);
  const [viewCropsData, setViewCropsData] = useState<any>({});
  const [cropData, setCropData] = useState<any>({});

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Edit, View  Model/ Dialog
  const [viewCropDataDialog, setViewCropDataDialog] = useState<boolean>(false);
  const [addPackingSizeDialog, setAddPackingSizeDialog] = useState<boolean>(false);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewLoading, setViewLoading] = useState<boolean>(false);
  const [isPackingArrayUpdate, setIsPackingArrayUpdate] = useState(false);

  const [packingSizeArray, setpackingSizeArray] = useState<any>([
    {
      packing_size: '',
      no_of_packets: '',
      weight: ''
    },
  ]);

  const [editId, setEditId] = useState<any>(null);

  // Error object
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});

  // use effect method
  useEffect(() => {
    getCropsDataFromAPI();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (isPackingArrayUpdate) {
      setIsPackingArrayUpdate(false);
      getPackingDetailArrayUI();
    }
  }, [isPackingArrayUpdate]);

  // Get Crops Data from API
  const getCropsDataFromAPI = async () => {
    // api call
    pageService
      .globalCropVariety()
      .then((response) => {
        // get response
        if (response) {
          const DataList = response;
          if (DataList.length == 0) {
            setCropsList([]);
          } else {
            setCropsList(DataList);
          }
          setPageLoad(true);
        } else {
          setPageLoad(false);
          setCropsList([]);
        }
      });
  };

  // open view crops data dialog
  const viewCropDetails = (rowData: any) => {
    setViewLoading(true);
    setViewCropsData(rowData?.packing_sizes);
    setViewCropDataDialog(true);
    setViewLoading(false);
  };

  // close view crops data dialog
  const hideCropDataDialog = () => {
    setViewCropDataDialog(false);
    setViewCropsData([]);
    setViewLoading(false);

  };

  // open add packing size data dialog
  const addPackingSize = (rowData: any) => {
    setAddPackingSizeDialog(true);
    setCropData(rowData);
  };

  // close add packing size data dialog
  const hidePackingSizeDialog = () => {
    setAddPackingSizeDialog(false);
    setCropData({});
    setErrors({});
    setIsError(false);
  };

  const getPackingDetailArrayUI = () => {
    return (
      <>
        {packingSizeArray.map((item: any, index: number) => (
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="packing_size">Packing size *</label>
              <InputText
                value={!window.cn(item.packing_size) ? item.packing_size : ""}
                name="packing_size"
                autoComplete="off"
                onChange={(e) => onHandleChange(e, 'packing_size', index)}
                className={errors['packing_size'] && 'p-invalid'}
              />
              <small className="p-invalid-txt">{errors['packing_size']}</small>
            </div>
            <div className="field col">
              <label htmlFor="no_of_packets">No. Of Packets *</label>
              <InputText
                 keyfilter="num"
                value={!window.cn(item.no_of_packets) ? item.no_of_packets : ""}
                name="no_of_packets"
                autoComplete="off"
                onChange={(e) => onHandleChange(e, 'no_of_packets', index)}
                className={errors['no_of_packets'] && 'p-invalid'}
              />
              <small className="p-invalid-txt">{errors['no_of_packets']}</small>
            </div>
            <div className="field col">
              <label htmlFor="weight">Weight *</label>
              <InputText
                 keyfilter="num"
                value={!window.cn(item.weight) ? item.weight : ""}
                name="weight"
                autoComplete="off"
                onChange={(e) => onHandleChange(e, 'weight', index)}
                className={errors['weight'] && 'p-invalid'}
              />
              <small className="p-invalid-txt">{errors['weight']}</small>
            </div>
            {index > 0 ? (
              <>
                <div className='flex' style={{ alignItems: "center" }}>

                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger mt-2"
                    onClick={() => deleteCrop(index)}
                  />
                </div>
              </>
            ) : (
              <div></div>
            )}
          </div>
        ))}
      </>
    );
  };

  // Delete crop
  const deleteCrop = (index: number) => {
    let deleteCropArray = packingSizeArray;
    deleteCropArray.splice(index, 1);
    setpackingSizeArray(deleteCropArray);
    setIsPackingArrayUpdate(true);
  };

  // onHandleChange for crops
  const onHandleChange = (e: any, name: string, index: number) => {
    let tempPackingArray = packingSizeArray;
    tempPackingArray[index][name] = e.target.value;
    setpackingSizeArray(tempPackingArray);
    setIsPackingArrayUpdate(true);
  };

  // Handle crops Data
  const handlePackingSizeArray = () => {
    const defaultObject = {
      crop_detail_id: '',
      no_of_packets: '',
      damage: ''
    };
    let array = packingSizeArray;
    array.push(defaultObject);
    setpackingSizeArray(array);
    setIsPackingArrayUpdate(true);
  };

  const onSubmit = () => {
    const { errors, isError } = globalPackingSizeValidate(packingSizeArray);
    setErrors(errors);
    setIsError(isError);

    if (!isError) {
      setLoading(true);

      //Request object
      let formRequestData = new FormData();
      formRequestData.append('swarnim_crop_id', cropData.global_crops.id);
      formRequestData.append('swarnim_variety_id', cropData.id);
      formRequestData.append('packing_size_details', JSON.stringify(packingSizeArray));

      // api call
      pageService.addGlobalPackingSize(formRequestData).then((result) => {
        setAddPackingSizeDialog(false);
        setpackingSizeArray([
          {
            packing_size: '',
            no_of_packets: '',
            weight: ''
          }
        ]);
        setCropData({});
        setLoading(false);
        setErrors({});
        setIsError(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: result.message,
        });
        getCropsDataFromAPI();
      }).catch(error => {
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error,
        });
      }
      );
    }
  }

  //open edit dialog
  const editCrops = async (rowData: any) => {
    setAddPackingSizeDialog(true);
    setEditId(rowData.id);
    setCropData(rowData);

  };

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search by variety..."
          />
        </span>
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          label="Add Packing Size"
          className="p-button p-button-secondary mr-3"
          onClick={() => addPackingSize(rowData)}
          disabled={(rowData?.packing_sizes).length == 0 ? false : true}
        />
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          style={{ marginRight: '17px' }}
          onClick={() => viewCropDetails(rowData)}
          disabled={(rowData?.packing_sizes).length == 0 ? true : false}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-3"
          onClick={() => editCrops(rowData)}
          // disabled={(rowData?.packing_sizes).length == 0 ? true : false}
          disabled={true}
        />

      </div>
    );
  };

  const cropBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.global_crops?.name}</div>
      </>);
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'> Crop List </h4>
              <Button
                label="Add New Crop"
                icon="pi pi-plus"
                className="p-button-success mr-2"
                onClick={() => navigate('/stock/add-global-crop')}
              />
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={cropsList}
                  paginator={cropsList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Crops Data Found"
                >
                  {GlobalCropsColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'global_crops') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={cropBodyTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {GlobalCropsColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* View Dialog start*/}
      <Dialog
        visible={viewCropDataDialog}
        style={{ width: '650px' }}
        className='crud-demo '
        header="View Crop Data"
        modal
        footer
        onHide={hideCropDataDialog}
      >
        <DataTable
          className="datatable-responsive"
          value={viewCropsData}
          rows={defaultRowOptions}
          currentPageReportTemplate={showingEntries}
          emptyMessage="No Crops Varieties Data Found"
        >
          {GlobalCropsViewColumns.map((col, i) => {
            if (col.field === 'sr_no') {
              return (
                <Column
                  key={col.field}
                  header={col.header}
                  body={(_, { rowIndex }) => rowIndex + 1} />
              );
            } else {
              return (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              );
            }
          })}
        </DataTable>
      </Dialog>
      {/* View Dialog End*/}

      {/* Add Packing Size Dialog start*/}
      <Dialog
        visible={addPackingSizeDialog}
        style={{ width: '700px' }}
        className='crud-demo'
        header="Add Packing Size"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hidePackingSizeDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-text"
              onClick={onSubmit}
            />
          </>
        }
        onHide={hidePackingSizeDialog}
      >

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="name">Crop</label>
            <InputText
              value={cropData?.global_crops?.name}
              name="crop"
              autoComplete="off"
              disabled
              readOnly
            />
          </div>

          <div className="field col">
            <label htmlFor="variety">Variety</label>
            <InputText
              value={cropData.name}
              name="variety"
              disabled
              readOnly
            />
          </div>
        </div>

        {getPackingDetailArrayUI()}

        <div className="field">
          <div className="field col">
            <Button
              style={{ width: 'fit-content' }}
              label="Add More"
              icon="pi pi-plus"
              className="p-button"
              onClick={handlePackingSizeArray}
            />
          </div>
        </div>
      </Dialog>
      {/* Add Packing Size Dialog End*/}

      {/* Loader Start */}
      {
        viewLoading && <Loader />
      }
    </>
  );
};