//interface for columns
interface ColumnMeta {
  field: string;
  header: string;
}

//ProductColumns
export const ProductColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'farmer_name', header: 'Farmer Name' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'split_from_lot_no', header: 'Split From Lot No.' },
  { field: 'crop_qnty', header: 'Received Qty.' },
  { field: 'created_at', header: 'Date' },
  { field: 'type', header: 'Type' },
  { field: 'action', header: 'Action' },
];

//Attendance Columns
export const AttendanceColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'name', header: 'Name' },
  { field: 'vehicle_type', header: 'Vehicle Type' },
  { field: 'start_time', header: 'Start Date' },
  { field: 'end_time', header: 'End Date' },
  { field: 'distance', header: 'Distance' },
  { field: 'start_image', header: 'Start Image' },
  { field: 'end_image', header: 'End Image' },
];

//Leave management Columns
export const LeaveColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'name', header: 'Name' },
  { field: 'from_date', header: 'From Date' },
  { field: 'to_date', header: 'To Date' },
  { field: 'leave_day', header: 'Type' },
  { field: 'reason', header: 'Reason' },
  { field: 'action', header: 'Action' },
];

export const ExpenseColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'name', header: 'Full Name' },
  { field: 'from_date', header: 'From Date' },
  { field: 'to_date', header: 'To Date' },
  { field: 'tour_type', header: 'Expense Type' },
  { field: 'distance', header: 'Distance' },
  { field: 'amount', header: 'Amount' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

// Dealer columns
export const DealerColumns: ColumnMeta[] = [
  { field: 'sr_no.', header: 'Sr No.' },
  { field: 'firm_name', header: 'Firm Name' },
  { field: 'full_name', header: 'Name' },
  { field: 'mobile', header: 'Mobile' },
  { field: 'state', header: 'State' },
  { field: 'created_at', header: 'Date' },
  { field: 'is_hold', header: 'Status' },
  { field: 'action', header: 'Action' },
];

// Dealer Lead columns
export const DealerLeadColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'photo', header: 'Image' },
  { field: 'firm_name', header: 'Firm Name' },
  { field: 'full_name', header: 'Full Name' },
  { field: 'lead_added_user_name', header: 'Lead Added By' },
  { field: 'created_at', header: 'Date' },
  { field: 'mobile', header: 'Mobile' },
  { field: 'action', header: 'Action' },
];

// Farmer columns
export const FarmerColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'organizer', header: 'Organizer Name' },
  { field: 'created_at', header: 'Date' },
  { field: 'action', header: 'Action' },
];

// Farmer columns
export const FarmerViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Farmer Name' },
  { field: 'mobile', header: 'Mobile' },
  { field: 'state', header: 'State' },
  { field: 'district', header: 'District' },
  { field: 'taluka', header: 'Taluka' },
  { field: 'village', header: 'Village' },
  { field: 'created_at', header: 'Date' },
  { field: 'action', header: 'Action' },
];

//AgentColumns
export const AgentColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'name', header: 'Name' },
  { field: 'mobile', header: 'Mobile' },
  { field: 'residence_address', header: 'Address' },
  { field: 'state', header: 'State' },
  { field: 'city', header: 'City' },
  { field: 'created_at', header: 'Date' },
  { field: 'action', header: 'Action' },
];

export const EmployeeColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr. No.' },
  { field: 'photo', header: 'Image' },
  { field: 'name', header: 'Name' },
  { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile' },
  { field: 'role', header: 'Role' },
  { field: 'created_at', header: 'Date' },
  { field: 'action', header: 'Action' }, 
];

// global crops columns
export const GlobalCropsColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'global_crops', header: 'Crop' },
  { field: 'name', header: 'Variety' },
  { field: 'action', header: 'Action' },
];

export const GlobalCropsViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'packing_size', header: 'Packing Size' },
  { field: 'no_of_packets', header: 'No. Of Packets' },
  { field: 'weight', header: 'Weight' },
];

export const CropsColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'weight', header: 'Weight' },
  { field: 'quantity', header: 'Quantity' },
  { field: 'action', header: 'Action' },
];

export const CropsViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'state', header: 'State' },
  { field: 'amount', header: 'Amount' },
];

export const OrderColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr. No.' },
  { field: 'order_date', header:  'Date' },
  { field: 'user_id', header: 'Added By' },
  { field: 'dealer_id', header: 'Dealer' },
  { field: 'order_amount', header: 'Amount' },
  { field: 'executive_approve', header: 'Approved By' },
  { field: 'status', header: 'Approve Order' },
  { field: 'admin_approve_id', header: 'Status' },
  { field: 'action', header: 'Action' },
  
];

export const ordersViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr. No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'packing_size', header: 'Packing Size' },
  { field: 'no_of_packets', header: 'No Of Packets' },
  { field: 'quantity', header: 'No Of Bags' },
  { field: 'total_weight', header: 'Weight' },
  { field: 'total_amount', header: 'Amount' }, 
];

export const ReturnOrderColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr. No.' },
  { field: 'return_added_by_user', header: 'Return Order Added By' },
  { field: 'created_at', header: 'Date' },
  { field: 'action', header: 'Action' },
];

export const returnOrderViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr. No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'packing_size', header: 'Packing Size' },
  { field: 'quantity', header: 'No Of Packets' },
];

export const orderTransferColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr. No.' },
  { field: 'order_transfered_by', header: 'Transferred From' },
  { field: 'order_transfer_to', header: 'Transferred To' },
  { field: 'type', header: 'Type' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'created_at', header: 'Date' },
  { field: 'status', header: 'Approve' },
  { field: 'received_qty', header: 'Received Qty.' },
  { field: 'action', header: 'Action' },
];

//ProductColumns
export const ProductViewColumns: ColumnMeta[] = [
  { field: 'unprocess', header: 'Physical Purtiy' },
  { field: 'germination', header: 'Germination' },
  { field: 'field_emergence', header: 'Field Emergence' },
  { field: 'genetic_purity', header: 'Genetic Purity' },
];

//ProductColumns
export const SplitViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'crop_qnty', header: 'Quantity' },
  { field: 'no_of_bags', header: 'No Of Bags' },
  { field: 'created_at', header: 'Date' },
];

//ProductColumns
export const MergeViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'farmer_name', header: 'Farmer Name.' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'crop_qnty', header: 'Quantity' },
  { field: 'no_of_bags', header: 'No Of Bags' },
];

//doctor testColumns
export const TestColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'code_no', header: 'Code No.' },
  { field: 'crop_name', header: 'Crop' },
  { field: 'variety_name', header: 'Variety' },
  { field: 'created_at', header: 'Date' },
  { field: 'germination', header: 'Germination' },
  { field: 'field_emergence', header: 'Field Emergence' },
  { field: 'genetic_purity', header: 'Genetic Purity' },
  { field: 'action', header: 'Action' },
];

export const ProcessColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'farmer_name', header: 'Farmer Name' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'crop_qnty', header: 'Unprocessed Qty.' },
  { field: 'remaining_unprocessed_qty', header: 'Remaining Unprocessed Qty.' },
  { field: 'crop_division', header: 'Class' },
  { field: 'process', header: 'Processed Date' },
  { field: 'status', header: 'Process Data' },
  { field: 'action', header: 'Action' },
];

export const ProcessViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'processed_qnty', header: 'Processed Quantity' },
  { field: 'remenant', header: 'Remenant' },
  { field: 'losses', header: 'Processed Loss' },
  { field: 'data_added_by_user', header: 'Data Added By' },
  { field: 'data_edited_by_user', header: 'Data Edited By' },
  { field: 'created_at', header: 'Date' },
  { field: 'edit_status', header: 'Action' },
];

//DeleteProductColumns
export const DeleteProductColumns: ColumnMeta[] = [
  { field: 'check', header: '' },
  { field: 'code', header: 'Code' },
  { field: 'name', header: 'Name' },
  { field: 'image', header: 'Image' },
  { field: 'category', header: 'Category' },
  { field: 'quantity', header: 'Quantity' },
  { field: 'action', header: 'Action' },
];

export const PackagingColumns : ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'lotNo', header: 'Lot No.' },
  { field: 'processed_qnty', header: 'Processed Qty' },
  { field: 'remaining_qnty', header: 'Remaining Qty' },
  { field: 'crop_division', header: 'Division' },
  { field: 'action', header: 'Action' },
];

export const viewPackingColumns : ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'bag_weight', header: 'Packing Size' },
  { field: 'no_of_packets', header: 'No. Of Packets Used' },
  { field: 'damage', header: 'Damage Packets' },
  { field: 'net_packets', header: 'Net Packets' },
  { field: 'packed_qnty', header: 'Packed Quantity' },
  { field: 'losses', header: 'Packing Loss' },
  { field: 'lable_from', header: 'Label From' },
  { field: 'lable_to', header: 'Label To' },
  { field: 'missing_label', header: 'Missing Label' },
  { field: 'chemical', header: 'Chemical Used' },
  { field: 'data_added_by_user', header: 'Data Added By' },
];

export const DisptachColumns : ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'order_id', header: 'Type' },
  { field: 'dispatch_type', header: 'Dispatch Type' },
  { field: 'transport_type', header: 'Transport Type' },
  { field: 'bill_to', header: 'Bill To' },
  { field: 'created_at', header: 'Date' },
  { field: 'action', header: 'Action' },
];

export const DisptachViewColumns : ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'crop_name', header: 'Crop' },
  { field: 'variety_name', header: 'Variety' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'packing_size', header: 'Packing Size' },
  { field: 'no_of_bag', header: 'No Of Bag' },
  { field: 'action', header: 'Action' },
];

//ProductionColumns
export const ProductionColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'organizer', header: 'Organizer Name' },
  { field: 'total_area', header: 'Total Area(in acres)' },
  { field: 'created_at', header: 'Date' },
  { field: 'action', header: 'Action' },
];

export const ProductionViewColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'farmer_name', header: 'Farmer Name' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'stage', header: 'Class' },
  { field: 'village', header: 'Village' },
  { field: 'sowing_date', header: 'Sowing Date' },
  { field: 'area', header: 'Distributed Area(in acres)' },
  { field: 'inspection', header: 'Inspection Completed' },
  { field: 'status', header: 'Raw Material Addded' },
  { field: 'action', header: 'Action' },
];

export const SamplingColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'farmer_name', header: 'Farmer Name' },
  { field: 'village', header: 'Village' },
  { field: 'taluka', header: 'Taluka' },
  { field: 'district', header: 'District' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'stage', header: 'Class' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'sampled_by', header: 'Sampled By' },
  { field: 'sampling_date', header: 'Sampling Date' }
];

export const InspectionColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'farmer_name', header: 'Name of Grower' },
  { field: 'village', header: 'Village' },
  { field: 'taluka', header: 'Taluka' },
  { field: 'district', header: 'District' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'stage', header: 'Class' },
  { field: 'area', header: 'Existing Area(ac)' },
  { field: 'sowing_date', header: 'Sowing Date' },
];

export const TestingColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'crop_obj', header: 'Crop' },
  { field: 'variety_obj', header: 'Variety' },
  { field: 'stage', header: 'Class' },
  { field: 'sampling_result', header: 'Physical Purity' },
  { field: 'germination', header: 'Germination' },
  { field: 'field_emergence', header: 'Field Emergence' },
  { field: 'genetic_purity', header: 'Genetic Purity' }
];

export const LoadingUnloadingColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'conractor_name', header: 'Name of Labour Contactor' },
  { field: 'crop', header: 'Crop' },
  { field: 'labour_no_of_bags', header: 'No of Bag Loading /Unloading' },
  { field: 'price_per_bag', header: 'Charges Per bag' }
];

export const PackingMaterialColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'crop_division', header: 'Class' },
  { field: 'weight', header: 'Unit Size' },
  { field: 'crop_qnty', header: 'Received Qty' },
  { field: 'no_of_packets', header: 'Used' },
  { field: 'damage', header: 'Damage' }
];

export const CropWisePackagingColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'packing_size', header: 'Packing Size' },
  { field: 'packing_bag_count', header: 'Bag Count' },
  { field: 'action', header: 'Action' },
];

export const LotNoWiseStock: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'lot_no', header: 'Lot No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'action', header: 'Action' }
];

export const lotNoChildColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'packing_size', header: 'Packing Size' },
  { field: 'quantity', header: 'Quantity' },
  { field: 'action', header: 'Action' }
];

export const GrowerColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'farmer_name', header: 'Name of Grower' },
  { field: 'village', header: 'Village' },
  { field: 'taluka', header: 'Taluka' },
  { field: 'district', header: 'District' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'stage', header: 'Class' },
  { field: 'area', header: 'Area(ac)' },
  { field: 'sowing_date', header: 'Date Of Sowing' },
];

export const GTMColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'storage', header: 'Storage (From) '},
  { field: 'processing', header: 'Processing (To) '},
  { field: 'amount', header: 'Amount '},
  { field: 'created_at', header: 'Date' },
  { field: 'action', header: 'Action' },
]

export const SalesmanColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr. No.' },
  { field: 'photo', header: 'Image' },
  { field: 'name', header: 'Name' },
  { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile' },
  { field: 'action', header: 'Action' }, 
];

//RemenantColumns
export const RemenantColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'crop', header: 'Crop' },
  { field: 'variety', header: 'Variety' },
  { field: 'remenant', header: 'Remenant Qty.' }
];

