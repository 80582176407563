import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { CSVLink } from "react-csv";
import { formatDateTime } from '../../appconfig/AppHelper';
import { Badge } from 'primereact/badge';
import { RadioButton } from 'primereact/radiobutton';

// Column
import { MergeViewColumns, ProductColumns, SplitViewColumns } from '../../appconfig/DatatableSetting';

//App Helper 
import { imageTemplate } from '../../appconfig/AppHelper';

//File Uplaod Components
import { chooseOptions, emptyTemplate, headerTemplate, } from '../../components/ImageUploadComponent/ImageUploadSetting';

// Data table
import { defaultPageRowOptions, defaultRowOptions, paginatorLinks, rawMaterialTypeDropdown, showingEntries, SkeletonbodyTemplate, Skeletonitems, } from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

// Dropdown
import { weightDropdown, cropDivisionDropdownItems, procunmentDropdown, PaymentDropdown, packingTypeDropdown } from '../../appconfig/Settings';

// Validatetion
import { ProductValidate, ProductPaymentValidate, SplitValidate } from '../../config/Validate';
import { ProgressSpinner } from 'primereact/progressspinner';

export const Product = () => {
  document.title = 'Product | Swarnim Farms';

  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [csvData, setCsvData] = useState([]);

  //Product Crud Object
  const [productData, setProuductData] = useState<any>({});
  const [afterRawMaterialData, setAfterRawMaterialData] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [rawMatetrialId, setRawMaterialId] = useState<any>("");

  // Drop down items data
  const [cropDivisionDropdownItem, setCropDropdownItem] = useState<any>(null);
  const [conditionSampleDropdownItem, setConditionSampleDropdownItem] =
    useState<any>(null);
  const [testRequiredDropdownItem, setTestRequiredDropdownItem] =
    useState<any>(null);

  // Error object
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});

  const [byPassTest, setBypassTest] = useState<any>('0');

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Edit and Delete Model/ Dialog
  const [productDialog, setProductDialog] = useState<boolean>(false);
  const [afterRawMaterialDialog, setAfterRawMaterialDialog] = useState<boolean>(false);

  // Edit and Delete Data Details
  const [editId, setEditId] = useState<any>(null);

  // crop and variety
  const [cropData, setCropData] = useState<any>([]);
  const [selectedCropId, setSelectedCropId] = useState<any>(null);
  const [selectedVarietyId, setSelectedVarietyId] = useState<any>(null);
  const [varietyData, setVarietyData] = useState<any>([]);

  const [selectedPayment, setSelectedPayment] = useState<any>({ name: 'PAID', code: 'PAID' });
  const [selectedProcunment, setSelectedProcunment] = useState<any>({ name: 'DIRECT PROCUNMENT', code: 'DIRECT PROCUNMENT' });
  const [selectedPackingType, setSelectedPackingType] = useState<any>(null);
  const [totalLabourPrice, setTotalLabourPrice] = useState<number>(0);

  const [lotNo, setLotNo] = useState<any>({});
  const [weight, setWeight] = useState<any>({ name: 'kg', code: 'kg' });

  // File Upload Details
  const fileUploadRef = useRef(null);
  const [image, setImage] = useState<any>();
  const removeFile = useRef(null);

  const [viewProductDialog, setViewProductDialog] = useState<boolean>(false);
  const [viewProductData, setViewProductData] = useState<any>({});
  const [viewSplitDialog, setViewSplitDialog] = useState<boolean>(false);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);

  const [spinner, setSpinner] = useState<boolean>(false);

  const [splitData, setSplitData] = useState<any>([{
    lot_no: '',
    crop_qnty: '',
    no_of_bags: ''
  }]);
  const [splitRawId, setSplitRawId] = useState<any>("");
  const [uiUpdated, setIsUiUpdated] = useState<boolean>(false);

  const [type, setType] = useState<any>({ name: 'All', code: 'All' });

  // use effect method
  useEffect(() => {
    getData();
    getCropList();
  }, [dates, type]);

  useEffect(() => {
    if (uiUpdated) {
      setIsUiUpdated(false);
    }
  }, [splitData, uiUpdated]);

  useEffect(() => {
    let tempAfterRawData = afterRawMaterialData.labour_no_of_bags * afterRawMaterialData.price_per_bag;
    let totalLabourCharge = tempAfterRawData;
    setTotalLabourPrice(totalLabourCharge);
  }, [afterRawMaterialData]);

  useEffect(() => {
    if (csvData.length > 0) {
      csvLink?.current?.link.click();
      setCsvData([]);
    }
  }, [csvData]);

  // Handle Select Crop
  const handleSelectedCrop = (value: any) => {
    if (!window.cn(value)) {
      setSelectedCropId(value);
      varietyList(value, 'null');
    } else {
      setSelectedCropId(null);
      setVarietyData([]);
    }
  };

  // Get crops
  const getCropList = async () => {
    try {
      const cropData: any = [];

      pageService.cropList().then((response) => {
        if (response) {
          const cropDataList = response;
          for (let i = 0; i < cropDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = cropDataList[i].name;
            userObj['code'] = cropDataList[i].id;
            cropData.push(userObj);
          }
          setCropData(cropData);
        }
      });

    } catch (error) { }
  };

  //Get City
  const varietyList = async (selectedCropId: any, extra: string) => {
    try {
      let cropId = !window.cn(selectedCropId) ? selectedCropId.code : '';
      const varietyData: any = [];
      pageService.varietyList(cropId).then((response) => {
        if (response) {
          const varietyDataList = response;
          for (let i = 0; i < varietyDataList.length; i++) {
            const varietyObj: any = {};
            varietyObj['name'] = varietyDataList[i].name;
            varietyObj['code'] = varietyDataList[i].id;
            varietyData.push(varietyObj);
          }
          setVarietyData(varietyData);
        }
      });
    } catch (error) { }
  };

  //onChange Product raw matrial Data
  const onInputChange = (e: any, name: string) => {
    let val = (e.target && e.target.value) || '';

    if (name == 'crop_grown_year') {
      val = val;
    } else if (name == 'receiving_date') {
      val = e;
    } else {
      val = val.toUpperCase();
    }
    setProuductData({ ...productData, [name]: val });
  };

  //onChange After Product raw matrial Data
  const onInputChangeAfterRawMaterial = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setAfterRawMaterialData({ ...afterRawMaterialData, [name]: val.toUpperCase() });
  };

  // get raw matrail product data
  const getData = async () => {
    setLoading(true);
    setErrors(false);
    setIsError(false);

    // Api call
    pageService
      .getProductRawMatrialData(type.code)
      .then((response) => {
        // Get response
        if (response) {
          const productData = response.data;
          if (productData.length == 0) {
            setProducts([]);
            setErrors(false);
            setLoading(false);
            setPageLoad(true);
          } else {
            setProducts(productData);
            setErrors(false);
            setLoading(false);
            setPageLoad(true);
          }
        } else {
          setProducts([]);
          setErrors(false);
          setLoading(false);
          setPageLoad(false);
        }
      });
  };

  // on date change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  // dropdown handle change packing type
  const handlePackingTypeDropdown = (id: any) => {
    setSelectedPackingType(id);
  };

  // dropdown handle change procunment
  const handleProcunmentDropdown = (id: any) => {
    setSelectedProcunment(id);
  };

  // dropdown handle change paymnet
  const handleChangePayment = (id: any) => {
    setSelectedPayment(id);
  };

  // add raw matrial data
  const onSubmit = async () => {

    const { errors, isError } = ProductValidate(productData, cropDivisionDropdownItem, selectedCropId, selectedProcunment, selectedPackingType, selectedVarietyId, image);
    setErrors(errors);
    setIsError(isError);

    //Check Error if no errors then call API
    if (!isError) {
      setLoading(true);
      let formRequestData = new FormData();
      if (!window.cn(productData) && productData.farmer_name) {
        formRequestData.append('farmer_name', productData?.farmer_name);
      }
      if (!window.cn(productData) && productData.district) {
        formRequestData.append('district', productData?.district);
      }
      if (!window.cn(productData) && productData.village) {
        formRequestData.append('village', productData?.village);
      }
      if (!window.cn(productData) && productData.taluka) {
        formRequestData.append('taluka', productData?.taluka);
      }
      if (!window.cn(productData) && productData.lot_no) {
        formRequestData.append('lot_no', productData?.lot_no);
      }

      if (!window.cn(productData) && productData.seed_organizer) {
        formRequestData.append('seed_organizer', productData?.seed_organizer);
      }

      formRequestData.append('weight_in', weight.code);
      if (!window.cn(productData) && productData.location) {
        formRequestData.append('location', productData?.location);
      }
      if (!window.cn(productData) && productData.crop_grown_year) {
        formRequestData.append('crop_grown_year', productData?.crop_grown_year.getFullYear());
      }
      if (!window.cn(productData) && productData.ginning_centre) {
        formRequestData.append('ginning_centre', productData?.ginning_centre);
      }
      if (!window.cn(productData) && productData.processing_center) {
        formRequestData.append('processing_center', productData?.processing_center);
      }
      if (selectedPackingType != null) {
        formRequestData.append('packing_type', selectedPackingType?.code);
      }
      formRequestData.append('crop_qnty', productData?.crop_qnty);
      formRequestData.append('no_of_bags', productData?.no_of_bags);
      formRequestData.append('crop_division', cropDivisionDropdownItem.code);
      formRequestData.append('crop', selectedCropId.code);
      formRequestData.append('variety', selectedVarietyId.code);
      formRequestData.append('procunment', selectedProcunment.code);

      if (!window.cn(productData) && productData.receiving_date) {
        var date = new Date(productData.receiving_date);
        let receivingDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        formRequestData.append('receiving_date', receivingDate);
      }

      if (editId !== undefined && editId !== null) {
        formRequestData.append('id', editId);

        // set image if it is uploaded
        if (image && image.name) {
          formRequestData.append('photo', image);
        }
      } else {
        if (image !== undefined && image !== null && image !== "") {
          formRequestData.append('photo', image);
        }
        formRequestData.append('bypass_test', byPassTest);

      }

      // api call
      pageService.addRawMatrialSlipData(formRequestData).then(response => {
        if (editId == null) {
          setRawMaterialId(response.data.id)
          setLotNo(response.data);
          setAfterRawMaterialDialog(true);
          toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Data Addded Sucessfully',
          });
        } else {
          toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Data Updated Sucessfully',
          });
        }
        setProductDialog(false);
        setProuductData({ productData });
        setErrors(false);
        setLoading(false);
        getData();
        setSelectedVarietyId(null);
        setSelectedCropId(null);
        setCropDropdownItem("");
        setSelectedProcunment({ name: 'DIRECT PROCUNMENT', code: 'DIRECT PROCUNMENT' });
        setSelectedPackingType(null);
        setVarietyData([]);
        setWeight({ name: 'kg', code: 'kg' });
        setImage(null);
        setEditId(null);
      }).catch(error => {
        setProductDialog(true);
        setErrors(false);
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error
        });
      });
    }
  };

  // add salary slip data
  const onSubmitAfterRawMaterialAdd = async () => {
    const { errors, isError } = ProductPaymentValidate(afterRawMaterialData, selectedPayment.code);
    setErrors(errors);
    setIsError(isError);

    //Check Error if no errors then call API
    if (!isError) {
      setLoading(true);

      let requestData = afterRawMaterialData;
      requestData["transport_paid"] = selectedPayment.code;
      requestData["id"] = rawMatetrialId;
      requestData["total_labour_price"] = totalLabourPrice;

      // Api call
      pageService.addAfterRawmaterial(requestData).then((response) => {
        setAfterRawMaterialDialog(false);
        setAfterRawMaterialData({});
        setErrors(false);
        setLoading(false);
        setSelectedProcunment({ name: 'DIRECT PROCUNMENT', code: 'DIRECT PROCUNMENT' });
        getData();
        setCropDropdownItem("");
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Sample Slip Deatils Added.',
        });
      }).catch(error => {
        setAfterRawMaterialDialog(true);
        setAfterRawMaterialData({ afterRawMaterialData });
        setConditionSampleDropdownItem({ conditionSampleDropdownItem });
        setTestRequiredDropdownItem({ testRequiredDropdownItem });
        setErrors(false);
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sample Slip Deatils not Added.',
        });
      });
    }
  };

  // open add raw matrial product data dialog
  const addDialog = () => {
    setProductDialog(true);
  };

  // open add sample slip data dialog
  const hideAfterRawMaterialDialog = () => {
    setAfterRawMaterialDialog(false);
    setAfterRawMaterialData({});
    setEditId(null);
    setErrors({});
    {
      !window.cn(removeFile.current) && onTemplateRemove(removeFile?.current);
    }
  };

  // close add salary slip dialog
  const closeAddDialog = () => {
    setEditId(null);
    setProductDialog(false);
    setProuductData({});
    setSelectedProcunment({ name: 'DIRECT PROCUNMENT', code: 'DIRECT PROCUNMENT' });
    setSelectedPackingType(null);
    setCropDropdownItem("");
    setSelectedVarietyId(null);
    setSelectedCropId(null);
    setVarietyData([]);
    setErrors({});
    setWeight({ name: 'kg', code: 'kg' });
  };

  const viewRawMaterailData = (rowData: any) => {
    setSpinner(true);
    setViewProductDialog(true);
    setViewProductData(rowData);
    setSpinner(false);
  }

  const viewSplitData = (rowData: any) => {
    setSpinner(true);
    setViewSplitDialog(true);
    setSplitRawId(rowData.id);
    setSpinner(false);
  }

  // close split dialog
  const hideSplitDialog = () => {
    setViewSplitDialog(false);
    setSplitData([{
      lot_no: '',
      crop_qnty: '',
      no_of_bags: ''
    }]);
    setSplitRawId("");
    setErrors({});
  };

  const onHandleChangeSplitData = (e: any, index: number, name: string) => {
    let tempArray = splitData;

    tempArray[index][name] = e.target.value;

    setSplitData(tempArray);
    setIsUiUpdated(true);
  }

  //handle add more
  const handleAddMore = () => {
    setSplitData([...splitData, {
      lot_no: '',
      crop_qnty: '',
      no_of_bags: ''
    }]);
  }

  //delete add more
  const deleteAddMore = (index: number) => {
    splitData.splice(index, 1);
    setIsUiUpdated(true);
  }

  // close data dialog
  const hideProductDialog = () => {
    setViewProductDialog(false);
    setViewProductData([]);
    setErrors({});
    setCropDropdownItem({});
    setSpinner(false);
  };

  // add split data
  const onSplitSubmit = async () => {

    const { errors, isError } = SplitValidate(splitData);
    setErrors(errors);
    setIsError(isError);

    //Check Error if no errors then call API
    if (!isError) {
      setLoading(true);

      let formData = new FormData();

      formData.append('split_raw_id', splitRawId);

      formData.append('split_details', JSON.stringify(splitData));

      // Api call
      pageService.addSplitRawData(formData).then((response) => {
        // Get Response
        setLoading(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        });
        setViewSplitDialog(false);
        setSplitData([{
          lot_no: '',
          crop_qnty: '',
          no_of_bags: ''
        }]);
        setSplitRawId("");
        getData();
      }).catch(error => {
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
      });
    }
  };

  //open edit dialog
  const editProduct = (product: any) => {
    setEditId(product.id);
    product.receiving_date = new Date(product.receiving_date);
    product.crop_grown_year = new Date(product.crop_grown_year);
    setProuductData(product);

    setSelectedProcunment({
      name: product.procunment,
      code: product.procunment
    });

    setSelectedPackingType({
      name: product.packing_type,
      code: product.packing_type
    })

    setSelectedCropId({
      name: product.crop_obj.name,
      code: product.crop_obj.id,
    })

    setSelectedVarietyId({
      name: product.variety_obj.name,
      code: product.variety_obj.id,
    })

    setCropDropdownItem({
      name: product.crop_division,
      code: product.crop_division,
    })

    setWeight({
      name: product.weight_in,
      code: product.weight_in,
    });

    setImage(product.photo);
    setBypassTest(product.bypass_test);
    setProductDialog(true);

  };

  // Template for image upload
  const itemImageTemplate = (file: any, props: any) => {
    setImage(file);
    removeFile.current = props.onRemove;
    return (
      <>
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img
              alt={file.name}
              role="presentation"
              src={file.objectURL}
              width={100}
            />
            <span className="flex flex-column text-left ml-3">{file.name}</span>
            <Button
              type="button"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger ml-auto"
              onClick={() => onTemplateRemove(props.onRemove)}
            />
          </div>
        </div>
      </>
    );
  };

  // remove image template
  const onTemplateRemove = (callback: any) => {
    setImage({});
    callback();
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions" style={{ display: 'flex' }}>
        <Button
          icon="pi pi-clone"
          tooltip="Split"
          tooltipOptions={{ position: 'bottom' }}
          className="p-button p-button-primary mt-2 mr-3 mb-2"
          onClick={() => viewSplitData(rowData)}
          disabled={rowData.process.length > 0}
        />
        <Button
          icon="pi pi-eye"
          tooltip="View"
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-primary mt-2 mr-3 mb-2"
          onClick={() => viewRawMaterailData(rowData)}
        />
        {localStorage.hasOwnProperty('role') && localStorage.getItem('role') == '1' ?
          <Button
            icon="pi pi-pencil"
            tooltipOptions={{ position: 'bottom' }}
            tooltip='Edit'
            className="p-button-rounded p-button-primary mt-2 mb-2"
            onClick={() => editProduct(rowData)}
            disabled={rowData.packing.length > 0}
          /> : ''}
      </div>
    );
  };

  const typeTemplate = (rowData: any) => {
    return (
      <>
        <div>
          {rowData.type == 'original' ?
            <Badge value={rowData.type} severity="success"></Badge> :
            rowData.type == 'split' ?
              <Badge value={rowData.type} severity="info"></Badge> :
              rowData.type == 'merge' ?
                <Badge value={rowData.type} severity="warning"></Badge> : ""}
        </div>
      </>
    )
  };

  const cropBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.crop_obj?.name}</div>
      </>
    )
  };

  const varietyBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.variety_obj?.name}</div>
      </>
    )
  };

  const farmerTemplate = (rowData: any) => {
    return (
      <>
        <div>{!window.cn(rowData.farmer_name) ? rowData?.farmer_name : "-"}</div>
      </>
    )
  };

  const splitLotNoTemplate = (rowData: any) => {
    return (
      <>
        <div>{!window.cn(rowData.split_from_lot_no) ? rowData?.split_from_lot_no : "-"}</div>
      </>
    )
  };

  //Start Date Time Conversion Template
  const dateFormatStartTimeTemplate = (rowData: any) => {
    return (
      <>
        <div>{formatDateTime(rowData?.created_at)}</div>
      </>
    );
  };

  const qtyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.crop_qnty + " " + rowData?.weight_in}</div>
      </>
    )
  };

  // for left toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // for right toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        {/* <Calendar
          value={dates}
          dateFormat="dd/mm/yy"
          onChange={(e) => onDateChange(e)}
          selectionMode="range"
          showIcon
        /> */}
        Type :
        <Dropdown
          value={type}
          onChange={(e) => setType(e.value)}
          options={rawMaterialTypeDropdown}
          optionLabel="name"
          placeholder="Raw Materail Type"
        ></Dropdown>
        <div style={{ marginLeft: '15px' }}></div>
        <Button
          label="Export"
          icon="pi pi-file-export"
          className="p-button-success mr-2"
          loading={csvLoading}
          onClick={exportCSV} />
        <CSVLink
          data={csvData}
          filename='Loading / Unloading Charges.csv'
          className='hidden'
          ref={csvLink}
          target='_blank'
        />
      </>
    );
  };

  //download csv
  const exportCSV = async () => {
    setCsvLoading(true);
    //Validation Chec
    pageService
      .getProductRawMatrialData(null)
      .then((response) => {
        // get response
        if (response) {
          let csvDetails = response.csv_data;
          if (csvDetails.length > 0) {
            setCsvData(csvDetails);
            setCsvLoading(false);
          }
        } else {
          setCsvData([]);
        }
      });
  }

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'> Raw Material List </h4>
              <Button
                label="Add New Product"
                icon="pi pi-plus"
                className="p-button-success mr-2"
                onClick={addDialog}
              />
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>

            {/* Datatable Start */}
            {pageLoad ? (
              <>
                <DataTable
                  value={products}
                  loading={loading}
                  paginator={products.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  className="datatable-responsive p-datatable-sm"
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Raw Material Data Found"
                >
                  {ProductColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'crop') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={cropBodyTemplate}
                        />
                      );
                    } else if (col.field === 'type') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={typeTemplate}
                        />
                      );
                    } else if (col.field === 'farmer_name') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={farmerTemplate}
                        />
                      );
                    } else if (col.field === 'split_from_lot_no') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={splitLotNoTemplate}
                        />
                      );
                    } else if (col.field === 'variety') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={varietyBodyTemplate}
                        />
                      );
                    } else if (col.field === 'created_at') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dateFormatStartTimeTemplate}
                          sortable
                        />
                      );
                    } else if (col.field === 'photo') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={imageTemplate}
                        />
                      );
                    } else if (col.field === 'crop_qnty') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={qtyTemplate}
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {ProductColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
            {/* Datatable End */}

            {/* Add Raw matrial Dialog Start*/}
            <Dialog
              visible={productDialog}
              style={{ width: '1000px' }}
              className="p-fluid"
              header="Add Raw Material Data"
              onHide={closeAddDialog}
              footer={
                <>
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={closeAddDialog}
                  />
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={onSubmit}
                    loading={loading}
                  />
                </>
              }
            >
              <div className="formgrid grid">

                {editId == null ?
                  <>
                    <div className="field col">
                      <label htmlFor="procunment">Procunment</label>
                      <Dropdown
                        id="procunment"
                        filter
                        value={selectedProcunment}
                        onChange={(e) => handleProcunmentDropdown(e.value)}
                        options={procunmentDropdown}
                        optionLabel="name"
                        name="procunment"
                        placeholder="Select"
                        className={errors['procunment'] && 'p-invalid'}
                      ></Dropdown>
                      <small className="p-invalid-txt">{errors['procunment']}</small>
                    </div>
                    <div className="field col">
                      <label htmlFor="crop">Crop *</label>
                      <Dropdown
                        filter
                        value={selectedCropId}
                        onChange={(e) => handleSelectedCrop(e.value)}
                        options={cropData}
                        optionLabel="name"
                        name="crop"
                        placeholder="Select Crop"
                        className={errors['crop'] && 'p-invalid'}
                      ></Dropdown>
                      <small className="p-invalid-txt">{errors['crop']}</small>
                    </div>
                    <div className="field col">
                      <label htmlFor="variety">Variety *</label>
                      <Dropdown
                        filter
                        value={selectedVarietyId}
                        onChange={(e) => setSelectedVarietyId(e.value)}
                        options={varietyData}
                        optionLabel="name"
                        name="variety"
                        placeholder="Select Variety"
                        className={errors['variety'] && 'p-invalid'}
                      ></Dropdown>
                      <small className="p-invalid-txt">{errors['variety']}</small>
                    </div>
                  </> : <>
                    <div className="field col">
                      <label htmlFor="name">Procunment</label>
                      <InputText
                        value={selectedProcunment.name}
                        name="procunment"
                        autoComplete="off"
                        disabled
                      />
                    </div>
                    <div className="field col">
                      <label htmlFor="name">Crop</label>
                      <InputText
                        value={selectedCropId.name}
                        name="crop"
                        autoComplete="off"
                        disabled
                      />
                    </div>
                    <div className="field col">
                      <label htmlFor="name">Variety</label>
                      <InputText
                        value={selectedVarietyId.name}
                        name="variety"
                        autoComplete="off"
                        disabled
                      />
                    </div>
                  </>}
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="name">Farmer Name</label>
                  <InputText
                    value={productData.farmer_name}
                    name="farmer_name"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'farmer_name')}
                    className={errors['farmer_name'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">
                    {errors['farmer_name']}
                  </small>
                </div>
                <div className="field col">
                  <label htmlFor="location">Storage Location</label>
                  <InputText
                    value={productData.location}
                    name="location"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'location')}
                    className={errors['location'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['location']}</small>
                </div>
                <div className="field col">
                  <label htmlFor="lot_no">Lot No.</label>
                  <InputText
                    value={productData.lot_no}
                    name="lot_no"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'lot_no')}
                    className={errors['lot_no'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['lot_no']}</small>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="village">Village</label>
                  <InputText
                    value={productData.village}
                    name="village"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'village')}
                    className={errors['village'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['village']}</small>
                </div>
                <div className="field col">
                  <label htmlFor="taluka">Taluka</label>
                  <InputText
                    value={productData.taluka}
                    name="taluka"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'taluka')}
                    className={errors['taluka'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['taluka']}</small>
                </div>
                <div className="field col">
                  <label htmlFor="district">District</label>
                  <InputText
                    value={productData.district}
                    name="district"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'district')}
                    className={errors['district'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['district']}</small>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="location">Seed Organizer</label>
                  <InputText
                    value={productData.seed_organizer}
                    name="seed_organizer"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'seed_organizer')}
                    className={errors['seed_organizer'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['seed_organizer']}</small>
                </div>
                <div className="field col">
                  <label htmlFor="packing_type">Packing Type</label>
                  <Dropdown
                    id="packing_type"
                    filter
                    value={selectedPackingType}
                    onChange={(e) => handlePackingTypeDropdown(e.value)}
                    options={packingTypeDropdown}
                    optionLabel="name"
                    name="packing_type"
                    placeholder="Select"
                    className={errors['packing_type'] && 'p-invalid'}
                  ></Dropdown>
                  <small className="p-invalid-txt">{errors['packing_type']}</small>
                </div>
                <div className="field col">
                  <label htmlFor="crop_grown_year">Production Year</label>
                  <Calendar
                    id="yearpicker"
                    value={productData.crop_grown_year}
                    onChange={(e) => onInputChange(e, 'crop_grown_year')}
                    view="year"
                    dateFormat="yy"
                  />
                  <small className="p-invalid-txt">
                    {errors['crop_grown_year']}
                  </small>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col-2">
                  <label htmlFor="crop_qnty">Recieved Quantity *</label>
                  <InputText
                    keyfilter="num"
                    value={productData.crop_qnty}
                    name="crop_qnty"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'crop_qnty')}
                    className={errors['crop_qnty'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['crop_qnty']}</small>
                </div>
                <div className="field col-2 flex " style={{ alignItems: "center", justifyContent: 'center', marginTop: "18px" }}>
                  <Dropdown
                    filter
                    value={weight}
                    onChange={(e) => setWeight(e.value)}
                    options={weightDropdown}
                    optionLabel="name"
                    placeholder="Select Weight"
                  ></Dropdown>
                </div>
                <div className="field col-4">
                  <label htmlFor="name">No. of bags *</label>
                  <InputText
                    keyfilter="num"
                    value={productData.no_of_bags}
                    name="no_of_bags"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'no_of_bags')}
                    className={errors['no_of_bags'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['no_of_bags']}</small>
                </div>
                {editId == null ?

                  <div className="field col-4">
                    <label htmlFor="crop_division">Class *</label>
                    <Dropdown
                      id="crop_division"
                      filter
                      value={cropDivisionDropdownItem}
                      onChange={(e) => setCropDropdownItem(e.value)}
                      options={cropDivisionDropdownItems}
                      optionLabel="name"
                      placeholder="Select One"
                      className={errors['crop_division'] && 'p-invalid'}
                    ></Dropdown>
                    <small className="p-invalid-txt">
                      {errors['crop_division']}
                    </small>
                  </div> : <div className="field col-4">
                    <label htmlFor="crop_division">Class</label>
                    <InputText
                      value={cropDivisionDropdownItem.name}
                      name="crop_division"
                      autoComplete="off"
                      disabled
                    />
                  </div>}
              </div>

              <div className="formgrid grid">
                <div className="field col-4">
                  <label htmlFor="receiving_date">Receiving Date *</label>
                  <Calendar
                    value={!window.cn(productData) ? productData.receiving_date : ""}
                    dateFormat="dd/mm/yy"
                    onChange={(e) => onInputChange(e.value, 'receiving_date')}
                    name="receiving_date"
                    showIcon
                    className={errors['receiving_date'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['receiving_date']}</small>
                </div>
                <div className="field col-4">
                  <label htmlFor="location">Processing Center</label>
                  <InputText
                    value={productData.processing_center}
                    name="processing_center"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'processing_center')}
                    className={errors['processing_center'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['processing_center']}</small>
                </div>
                <div className="field col-4">
                  <label htmlFor="ginning_centre">Ginning Centre</label>
                  <InputText
                    value={productData.ginning_centre}
                    name="ginning_centre"
                    onChange={(e) => onInputChange(e, 'ginning_centre')}
                    required
                    autoComplete="off"
                    className={errors['ginning_centre'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">
                    {errors['ginning_centre']}
                  </small>
                </div>
              </div>

              {editId == null ?
              <div className="p-fluid formgrid grid mb-5 mt-3">
                <div className="col-12 md:col-2 lg:col-2">
                  <label htmlFor="bypass_test" className="ml-2">By Pass Testing *</label>
                </div>
                <div className="col-12 md:col-1 lg:col-1">
                  <RadioButton inputId="1" name="type" value="1" onChange={(e) => setBypassTest(e.value)} checked={byPassTest == '1'} />
                  <label htmlFor="New" className="ml-1">Yes</label>
                </div>
                <div className="col-12 md:col-1 lg:col-1">
                  <RadioButton inputId="0" name="type" value="0" onChange={(e) => setBypassTest(e.value)} checked={byPassTest == '0'} />
                  <label htmlFor="Existing" className="ml-1">No</label>
                </div>
              </div> : ""}

              <div className="formgrid grid mt-3">
                <div className="field col-6">
                  <label htmlFor="photo">Way Bridge Copy</label>
                  <FileUpload
                    ref={fileUploadRef}
                    accept="image/*"
                    name="photo[]"
                    className="imageupload"
                    chooseOptions={chooseOptions}
                    emptyTemplate={emptyTemplate}
                    headerTemplate={headerTemplate}
                    itemTemplate={itemImageTemplate}
                  ></FileUpload>
                  <small className="p-invalid-txt">{errors['photo']}</small>
                </div>

                {editId != null && (
                  <>
                    <div className="field col-6 editImage">
                      {typeof (image) === 'string' ? (
                        <>  <label htmlFor="photo">Old Image</label>
                          <img src={image}></img></>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </div>

            </Dialog>
            {/* Add Raw matrial Dialog End*/}

            {/* Add After Raw Material Dialog Start*/}
            <Dialog
              visible={afterRawMaterialDialog}
              className="p-fluid"
              onHide={hideAfterRawMaterialDialog}
              footer={
                <>
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={onSubmitAfterRawMaterialAdd}
                    loading={loading}
                  />
                </>
              }
            >
              <h5>Payment Details</h5>
              <div className="field">
                <div className="field col">
                  <label htmlFor="name">Payment</label>
                  <Dropdown
                    id="payment"
                    filter
                    value={selectedPayment}
                    onChange={(e) => handleChangePayment(e.value)}
                    options={PaymentDropdown}
                    name="payment"
                    optionLabel="name"
                    placeholder="Select One"
                    className={errors['payment'] && 'p-invalid'}
                  ></Dropdown>
                  <small className="p-invalid-txt">
                    {errors['payment']}
                  </small>
                </div>
              </div>

              {
                !window.cn(selectedPayment) && selectedPayment.code == "TO PAY" ?
                  <>
                    <div className="formgrid grid">
                      <div className="field col">
                        <label htmlFor="crop">Vahicle No.</label>
                        <InputText
                          value={afterRawMaterialData.car_no}
                          name="car_no"
                          onChange={(e) => onInputChangeAfterRawMaterial(e, 'car_no')}
                          required
                          autoComplete="off"
                          className={errors['car_no'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">
                          {errors['car_no']}
                        </small>
                      </div>

                      <div className="field col">
                        <label htmlFor="transport_name">Transport Name / Driver Name</label>
                        <InputText
                          value={afterRawMaterialData.transport_name}
                          name="transport_name"
                          onChange={(e) => onInputChangeAfterRawMaterial(e, 'transport_name')}
                          required
                          autoComplete="off"
                          className={errors['transport_name'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['transport_name']}</small>
                      </div>

                      <div className="field col">
                        <label htmlFor="transport_amount">Amount</label>
                        <InputText
                           keyfilter="num"
                          value={afterRawMaterialData.amount}
                          name="transport_amount"
                          onChange={(e) => onInputChangeAfterRawMaterial(e, 'transport_amount')}
                          required
                          autoComplete="off"
                          className={errors['transport_amount'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['transport_amount']}</small>
                      </div>
                    </div>
                  </>
                  :
                  null
              }

              <hr></hr>
              <h5>Labour Details</h5>
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="conractor_name">Labour Conractor Name</label>
                  <InputText
                    value={afterRawMaterialData.conractor_name}
                    name="conractor_name"
                    onChange={(e) => onInputChangeAfterRawMaterial(e, 'conractor_name')}
                    required
                    autoComplete="off"
                    className={errors['conractor_name'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['conractor_name']}</small>
                </div>

                <div className="field col">
                  <label htmlFor="labour_no_of_bags">Number Of Bags</label>
                  <InputText
                     keyfilter="num"
                    value={afterRawMaterialData.labour_no_of_bags}
                    name="labour_no_of_bags"
                    onChange={(e) => onInputChangeAfterRawMaterial(e, 'labour_no_of_bags')}
                    required
                    autoComplete="off"
                    className={errors['labour_no_of_bags'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['labour_no_of_bags']}</small>
                </div>

                <div className="field col">
                  <label htmlFor="bag_weight">Weight of Bags</label>
                  <InputText
                     keyfilter="num"
                    value={afterRawMaterialData.bag_weight}
                    name="bag_weight"
                    onChange={(e) => onInputChangeAfterRawMaterial(e, 'bag_weight')}
                    required
                    autoComplete="off"
                    className={errors['bag_weight'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['bag_weight']}</small>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="price_per_bag">Price Per Bags</label>
                  <InputText
                     keyfilter="num"
                    value={afterRawMaterialData.price_per_bag}
                    name="price_per_bag"
                    onChange={(e) => onInputChangeAfterRawMaterial(e, 'price_per_bag')}
                    required
                    autoComplete="off"
                    className={errors['price_per_bag'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['price_per_bag']}</small>
                </div>

                <div className="field col">
                  <label htmlFor="price_per_bag">Total</label>
                  <InputText
                     keyfilter="num"
                    value={!window.cn(totalLabourPrice) && totalLabourPrice != undefined && totalLabourPrice != null ? totalLabourPrice : 0}
                    name="price_per_bag"
                    readOnly
                    disabled
                  />
                </div>
              </div>

            </Dialog>
            {/* Add After Raw Material Dialog End*/}

            {/* View Dialog start*/}
            <Dialog
              visible={viewProductDialog}
              style={{ width: '850px' }}
              header="View Raw Material Data"
              modal
              footer
              onHide={hideProductDialog}
            >

              {!window.cn(viewProductData.type) && viewProductData.type == 'original' ?
                <>
                  <div className="formgrid grid">
                    <div className="field col">
                      <p><b>Procunment</b> : {!window.cn(viewProductData.procunment) ? viewProductData.procunment : "NA"}</p>
                    </div>
                    <div className="field col">
                      <p> <b>Farmer Name : </b>  {!window.cn(viewProductData.farmer_name) ? viewProductData.farmer_name : "NA"}</p>
                    </div>

                    <div className="field col">
                      <p><b> Lot No.</b> : {!window.cn(viewProductData.lot_no) ? viewProductData.lot_no : "NA"}</p>
                    </div>
                  </div>

                  <div className="formgrid grid">
                    <div className="field col">
                      <p> <b>Crop</b> : {!window.cn(viewProductData.crop_obj?.name) ? viewProductData.crop_obj?.name : "NA"}</p>
                    </div>
                    <div className="field col">
                      <p> <b>Variety</b> : {!window.cn(viewProductData.variety_obj?.name) ? viewProductData.variety_obj?.name : "NA"}</p>
                    </div>
                    <div className="field col">
                      <p> <b>Recieved Quantity</b> : {!window.cn(viewProductData.crop_qnty) ? viewProductData.crop_qnty + " " + viewProductData.weight_in : "NA"}</p>
                    </div>
                  </div>

                  <div className="formgrid grid">
                    <div className="field col-4">
                      <p><b>Storage Location</b> : {!window.cn(viewProductData.location) ? viewProductData.location : "NA"}</p>
                    </div>
                    <div className="field col-4">
                      <p> <b>Processing Centre</b> : {!window.cn(viewProductData.processing_center) ? viewProductData.processing_center : "NA"}</p>
                    </div>

                    {!window.cn(viewProductData.ginning_centre) ? <>
                      <div className="field col-4">
                        <p> <b>Ginning Centre</b> : {!window.cn(viewProductData.ginning_centre) ? viewProductData?.ginning_centre : "NA"}</p>
                      </div>
                    </> : ""}
                  </div>

                  <div className="formgrid grid">
                    <div className="field col">
                      <p> <b>Taluka</b> : {!window.cn(viewProductData.taluka) ? viewProductData.taluka : "NA"}</p>
                    </div>

                    <div className="field col">
                      <p> <b>District</b> : {!window.cn(viewProductData.district) ? viewProductData.district : "NA"}</p>
                    </div>
                    <div className="field col">
                      <p> <b>Village</b> : {!window.cn(viewProductData.village) ? viewProductData.village : "NA"}</p>
                    </div>
                  </div>

                  <div className="formgrid grid">
                    <div className="field col">
                      <p> <b>Packing Type</b> : {!window.cn(viewProductData.packing_type) ? viewProductData.packing_type : "NA"}</p>
                    </div>

                    <div className="field col">
                      <p> <b>Seed Organizer</b> : {!window.cn(viewProductData.seed_organizer) ? viewProductData.seed_organizer : "NA"}</p>
                    </div>

                    <div className="field col">
                      <p> <b>Production Year</b> : {!window.cn(viewProductData.crop_grown_year) ? viewProductData.crop_grown_year : "NA"}</p>
                    </div>
                  </div>

                  <div className="formgrid grid">
                    <div className="field col-4">
                      <p> <b>No. of Bags</b> : {!window.cn(viewProductData.no_of_bags) ? viewProductData.no_of_bags : "NA"}</p>
                    </div>
                    <div className="field col-4">
                      <p> <b>Class</b> : {!window.cn(viewProductData.crop_division) ? viewProductData.crop_division : "NA"}</p>
                    </div>
                    <div className="field col-4">
                      <p> <b>Receiving Date</b> : {!window.cn(viewProductData.receiving_date) ? formatDateTime(viewProductData.receiving_date) : "NA"}</p>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="formgrid grid">
                    <div className="field col">
                      <p> <b>Transport PAID</b> : {!window.cn(viewProductData.transport_paid) ? viewProductData.transport_paid : "NA"}</p>
                    </div>
                  </div>

                  {viewProductData.transport_paid === 'PAID' ? (
                    <div></div>
                  ) : (
                    <div className="formgrid grid">
                      <div className="field col-4">
                        <p> <b>Car No.</b> : {!window.cn(viewProductData.car_no) ? viewProductData.car_no : "NA"}</p>
                      </div>

                      <div className="field col-4">
                        <p> <b>Transport Name</b> : {!window.cn(viewProductData.transport_name) ? viewProductData.transport_name : "NA"}</p>
                      </div>
                      <div className="field col-4">
                        <p> <b>Transport Amount</b> : {!window.cn(viewProductData.transport_amount) ? viewProductData.transport_amount : "NA"}</p>
                      </div>
                    </div>

                  )}

                  <div className="formgrid grid">
                    <div className="field col-4">
                      <p> <b>Labour Conractor Name</b> : {!window.cn(viewProductData.conractor_name) ? viewProductData.conractor_name : "NA"}</p>
                    </div>

                    <div className="field col-4">
                      <p> <b>Labour No. of Bags</b> : {!window.cn(viewProductData.labour_no_of_bags) ? viewProductData.labour_no_of_bags : "NA"}</p>
                    </div>

                    <div className="field col-4">
                      <p> <b>Weight of Bag</b> : {!window.cn(viewProductData.bag_weight) ? viewProductData.bag_weight : "NA"}</p>
                    </div>
                  </div>

                  <div className="formgrid grid">
                    <div className="field col-4">
                      <p> <b>Price Per Bag</b> : {!window.cn(viewProductData.price_per_bag) ? viewProductData.price_per_bag : "NA"}</p>
                    </div>
                    <div className="field col-4">
                      <p> <b>Total Labour Price</b> : {!window.cn(viewProductData.total_labour_price) ? viewProductData.total_labour_price : "NA"}</p>
                    </div>
                  </div>

                  <hr></hr>
                  {!window.cn(viewProductData.bypass_test) && viewProductData.bypass_test == 1 ?
                    <>
                      <div className="formgrid grid">
                        <div className="field col">
                          <p><span className="delete-dialog-note">All Test are By Pass</span></p>
                        </div>
                      </div>
                    </> : <>
                      <div className="formgrid grid">
                        <div className="field col">
                          <p> <b>Physical Purity Test</b> : Pass</p>
                        </div>

                        <div className="field col">
                          <p> <b>Germination Test</b> : {viewProductData.germination?.result ? viewProductData.germination?.result : "Pending"}</p>
                        </div>
                      </div>
                      <div className="formgrid grid">
                        <div className="field col">
                          <p> <b>Field Emergence Test</b> : {viewProductData.field_emergence?.result ? viewProductData.field_emergence?.result : "Pending"}</p>
                        </div>

                        <div className="field col">
                          <p> <b>Genetic Purtiy Test</b> : {viewProductData.genetic_purity?.result ? viewProductData.genetic_purity?.result : "Pending"}</p>
                        </div>
                      </div>
                    </>}

                  <div className="formgrid grid">
                    <div className="field col-4">
                      <p> <b>Way Bridge Copy</b> : {!window.cn(viewProductData) && viewProductData.photo ? <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-primary ml-3"
                        style={{ marginRight: '17px' }}
                        onClick={() => window.open(viewProductData.photo, '_blank')}
                      /> : ""}</p>
                    </div>
                  </div>
                  <div className="formgrid grid">
                    <div className="field col-6">
                      <p> <b>Raw Material Data Added By</b> : {!window.cn(viewProductData.user_id) ? viewProductData?.user?.first_name + " " + viewProductData?.user?.last_name : ""}</p>
                    </div>
                    {!window.cn(viewProductData.data_edited_by_user) ?
                      <div className="field col-6">
                        <p> <b>Raw Material Data Edited By</b> : {!window.cn(viewProductData.data_edited_by_user) ? viewProductData.data_edited_by_user : ""}</p>
                      </div> : ""}
                  </div>
                  {!window.cn(viewProductData.split_arr) ?
                    <>
                      <hr></hr>
                      <div className="field col-12">
                        <p><center><b> Split Details </b></center> </p>
                      </div>
                      <DataTable
                        value={viewProductData.split_arr}
                        loading={loading}
                        rows={defaultRowOptions}
                        rowsPerPageOptions={defaultPageRowOptions}
                        className="datatable-responsive"
                        paginatorTemplate={paginatorLinks}
                        currentPageReportTemplate={showingEntries}
                        emptyMessage="No Data Found"
                      >
                        {SplitViewColumns.map((col, i) => {
                          if (col.field === 'sr_no') {
                            return (
                              <Column
                                key={col.field}
                                header={col.header}
                                body={(_, { rowIndex }) => rowIndex + 1} />
                            );
                          } else if (col.field === 'created_at') {
                            return (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                body={dateFormatStartTimeTemplate}
                                sortable
                              />
                            );
                          } else {
                            return (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                sortable
                                filter
                              />
                            );
                          }
                        })}
                      </DataTable></> : ""}
                </> : !window.cn(viewProductData.type) && viewProductData.type == 'split' ?
                  <>
                    <div className="formgrid grid">
                      <div className="field col">
                        <p><b>Procunment</b> : {!window.cn(viewProductData.procunment) ? viewProductData.procunment : "NA"}</p>
                      </div>
                      <div className="field col">
                        <p> <b>Farmer Name : </b>  {!window.cn(viewProductData.farmer_name) ? viewProductData.farmer_name : "NA"}</p>
                      </div>

                      <div className="field col">
                        <p><b> Lot No.</b> : {!window.cn(viewProductData.lot_no) ? viewProductData.lot_no : "NA"}</p>
                      </div>
                    </div>

                    <div className="formgrid grid">
                      <div className="field col">
                        <p> <b>Crop</b> : {!window.cn(viewProductData.crop_obj?.name) ? viewProductData.crop_obj?.name : "NA"}</p>
                      </div>
                      <div className="field col">
                        <p> <b>Variety</b> : {!window.cn(viewProductData.variety_obj?.name) ? viewProductData.variety_obj?.name : "NA"}</p>
                      </div>
                      <div className="field col">
                        <p> <b>Recieved Quantity</b> : {!window.cn(viewProductData.crop_qnty) ? viewProductData.crop_qnty + " " + viewProductData.weight_in : "NA"}</p>
                      </div>
                    </div>

                    <div className="formgrid grid">
                      <div className="field col-4">
                        <p><b>Storage Location</b> : {!window.cn(viewProductData.location) ? viewProductData.location : "NA"}</p>
                      </div>
                      <div className="field col-4">
                        <p> <b>Processing Centre</b> : {!window.cn(viewProductData.processing_center) ? viewProductData.processing_center : "NA"}</p>
                      </div>

                      {!window.cn(viewProductData.ginning_centre) ? <>
                        <div className="field col-4">
                          <p> <b>Ginning Centre</b> : {!window.cn(viewProductData.ginning_centre) ? viewProductData?.ginning_centre : "NA"}</p>
                        </div>
                      </> : ""}
                    </div>

                    <div className="formgrid grid">
                      <div className="field col">
                        <p> <b>Taluka</b> : {!window.cn(viewProductData.taluka) ? viewProductData.taluka : "NA"}</p>
                      </div>

                      <div className="field col">
                        <p> <b>District</b> : {!window.cn(viewProductData.district) ? viewProductData.district : "NA"}</p>
                      </div>
                      <div className="field col">
                        <p> <b>Village</b> : {!window.cn(viewProductData.village) ? viewProductData.village : "NA"}</p>
                      </div>
                    </div>

                    <div className="formgrid grid">
                      <div className="field col">
                        <p> <b>Packing Type</b> : {!window.cn(viewProductData.packing_type) ? viewProductData.packing_type : "NA"}</p>
                      </div>

                      <div className="field col">
                        <p> <b>Seed Organizer</b> : {!window.cn(viewProductData.seed_organizer) ? viewProductData.seed_organizer : "NA"}</p>
                      </div>

                      <div className="field col">
                        <p> <b>Production Year</b> : {!window.cn(viewProductData.crop_grown_year) ? viewProductData.crop_grown_year : "NA"}</p>
                      </div>
                    </div>

                    <div className="formgrid grid">
                      <div className="field col-4">
                        <p> <b>No. of Bags</b> : {!window.cn(viewProductData.no_of_bags) ? viewProductData.no_of_bags : "NA"}</p>
                      </div>
                      <div className="field col-4">
                        <p> <b>Class</b> : {!window.cn(viewProductData.crop_division) ? viewProductData.crop_division : "NA"}</p>
                      </div>
                      <div className="field col-4">
                        <p> <b>Receiving Date</b> : {!window.cn(viewProductData.receiving_date) ? formatDateTime(viewProductData.receiving_date) : "NA"}</p>
                      </div>
                    </div>

                    <div className="formgrid grid">
                      <div className="field col-4">
                        <p> <b>Split From Lot No.</b> : {viewProductData.split_from_lot_no}</p>
                      </div>

                      <div className="field col-8">
                        <p> <b>Raw Material Data Added By</b> : {!window.cn(viewProductData.user_id) ? viewProductData?.user?.first_name + " " + viewProductData?.user?.last_name : ""}</p>
                      </div>
                    </div>
                    {!window.cn(viewProductData) && !window.cn(viewProductData.data_edited_by_user) ?
                      <div className="formgrid grid">
                        <div className="field col-4">
                          <p> <b>Raw Material Data Edited By</b> : {!window.cn(viewProductData.data_edited_by_user) ? viewProductData.data_edited_by_user : ""}</p>
                        </div>
                      </div> : ""}
                  </> : !window.cn(viewProductData.type) && viewProductData.type == 'merge' ?
                    <>
                      <div className="formgrid grid">
                        <div className="field col">
                          <p> <b>Crop</b> : {!window.cn(viewProductData.crop_obj?.name) ? viewProductData.crop_obj?.name : "NA"}</p>
                        </div>
                        <div className="field col">
                          <p> <b>Variety</b> : {!window.cn(viewProductData.variety_obj?.name) ? viewProductData.variety_obj?.name : "NA"}</p>
                        </div>
                        <div className="field col">
                          <p><b> Lot No.</b> : {!window.cn(viewProductData.lot_no) ? viewProductData.lot_no : "NA"}</p>
                        </div>
                      </div>

                      <div className="formgrid grid">
                        <div className="field col-4">
                          <p> <b>Recieved Quantity</b> : {!window.cn(viewProductData.crop_qnty) ? viewProductData.crop_qnty + " " + viewProductData.weight_in : "NA"}</p>
                        </div>
                        <div className="field col-4">
                          <p> <b>No. of Bags</b> : {!window.cn(viewProductData.no_of_bags) ? viewProductData.no_of_bags : "NA"}</p>
                        </div>
                        <div className="field col-4">
                          <p> <b>Raw Material Data Added By</b> : {!window.cn(viewProductData.user_id) ? viewProductData?.user?.first_name + " " + viewProductData?.user?.last_name : ""}</p>
                        </div>
                      </div>
                      <div className="formgrid grid">
                        <div className="field col-4">
                          <p> <b>Merge Type</b> : {!window.cn(viewProductData.merge_type) ? viewProductData.merge_type : "NA"}</p>
                        </div>
                        {!window.cn(viewProductData.data_edited_by_user) ?
                          <div className="field col-4">
                            <p> <b>Raw Material Data Edited By</b> : {!window.cn(viewProductData.data_edited_by_user) ? viewProductData.data_edited_by_user : ""}</p>
                          </div> : ""}
                      </div>

                      {!window.cn(viewProductData.merge_arr) ?
                        <>
                          <hr></hr>
                          <div className="field col-12">
                            <p><center><b>Merged From Lot No. Details</b></center> </p>
                          </div>
                          <DataTable
                            value={viewProductData.merge_arr}
                            loading={loading}
                            rows={defaultRowOptions}
                            rowsPerPageOptions={defaultPageRowOptions}
                            className="datatable-responsive"
                            paginatorTemplate={paginatorLinks}
                            currentPageReportTemplate={showingEntries}
                            emptyMessage="No Data Found"
                          >
                            {MergeViewColumns.map((col, i) => {
                              if (col.field === 'sr_no') {
                                return (
                                  <Column
                                    key={col.field}
                                    header={col.header}
                                    body={(_, { rowIndex }) => rowIndex + 1} />
                                );
                              } else if (col.field === 'created_at') {
                                return (
                                  <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={dateFormatStartTimeTemplate}
                                    sortable
                                  />
                                );
                              } else {
                                return (
                                  <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    sortable
                                    filter
                                  />
                                );
                              }
                            })}
                          </DataTable></> : ""}
                    </> : ""}

              {
                spinner && <div className="loading flex justify-content-center">
                  <ProgressSpinner />
                </div>
              }

            </Dialog>
            {/* View Dialog End*/}

            {/* Split Dialog start*/}
            <Dialog
              visible={viewSplitDialog}
              style={{ width: '850px' }}
              header="Split Raw Material Data"
              modal
              footer={
                <>
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={hideSplitDialog}
                  />
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={onSplitSubmit}
                    loading={loading}
                  />
                </>
              }
              onHide={hideSplitDialog}
            >

              {splitData.map((item: any, index: number) => (
                <>
                  <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-4">
                      <label htmlFor="lot_no">Lot No. *</label>
                      <InputText
                        value={!window.cn(splitData[index]["lot_no"]) ? splitData[index]["lot_no"] : ""}
                        name="lot_no"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeSplitData(e, index, "lot_no")}
                        placeholder='Enter New Lot No.'
                      />
                      <small className="p-invalid-txt">{errors['lot_no']}</small>
                    </div>
                    <div className="field col-12 md:col-4">
                      <label htmlFor="crop_qnty">Quantity *</label>
                      <InputText
                        value={!window.cn(splitData[index]["crop_qnty"]) ? splitData[index]["crop_qnty"] : ""}
                        name="crop_qnty"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeSplitData(e, index, "crop_qnty")}
                        placeholder='Enter Quantity'
                      />
                      <small className="p-invalid-txt">{errors['crop_qnty']}</small>
                    </div>
                    <div className="field col-12 md:col-4">
                      <label htmlFor="no_of_bags">No Of Bag *</label>
                      <InputText
                        value={!window.cn(splitData[index]["no_of_bags"]) ? splitData[index]["no_of_bags"] : ""}
                        name="no_of_bags"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeSplitData(e, index, "no_of_bags")}
                        placeholder='Enter No. Of Bags'
                      />
                      <small className="p-invalid-txt">{errors['no_of_bags']}</small>
                    </div>
                    {index > 0 ?
                      <div className="field col-12 md:col-2">
                        <Button
                          icon="pi pi-trash"
                          className="p-button-rounded p-button-danger mt-2"
                          onClick={() => deleteAddMore(index)} />
                      </div>
                      : ""
                    }
                  </div>
                  {/* <hr /> */}
                </>
              ))}
              <div className="field col-12 md:col-12">
                <Button
                  style={{ width: 'fit-content' }}
                  label="Add More"
                  icon="pi pi-plus"
                  className="p-button"
                  onClick={handleAddMore}
                />
              </div>

              {
                spinner && <div className="loading flex justify-content-center">
                  <ProgressSpinner />
                </div>
              }
            </Dialog>
            {/* Split Dialog End*/}
          </div>
        </div>
      </div>
    </>
  );
};
