import { APP_BASE_URL, convertDateFrom } from '../appconfig/Settings';
import {
  ADD_DEALER_DATA,
  ADD_EDIT_CROP_DATA,
  APPROVE_DEALER_LEAD,
  APPROVE_EXPENSE_DATA,
  CHANGE_LEAVE_STATUS,
  DELETE_CROP_DATA,
  DELETE_DEALER_DATA,
  EDIT_DEALER_DATA,
  GET_ATTENDANCE_DATA,
  GET_CROP_DATA,
  GET_CROP_DATA_BY_ID,
  GET_DEALER_BY_ID,
  GET_DEALER_DATA,
  GET_DEALER_LEAD_DATA,
  GET_EXPENSE_DATA,
  GET_FARMER_DATA,
  ADD_EDIT_FARMER_DATA,
  DELETE_FARMER_DATA,
  GET_FARMER_DATA_BY_ID,
  GET_LEAVE_DATA,
  GET_RAW_MATERIAL_LIST,
  RAW_MATERIAL_SLIP,
  ADD_AFTER_RAWMATERIAL_DATA,
  UPDATE_ATTENDANCE_STATUS,
  ADD_UPDATE_AGENT,
  GET_AGENT,
  GET_CITY,
  GET_STATE,
  DELETE_AGENT,
  GET_AGENT_BY_ID,
  ADD_UPDATE_ORDER,
  GET_ORDER_LIST,
  GET_ORDER_BY_ID,
  APPROVE_ORDER,
  DELETE_ORDER,
  ADD_EMPLOYEE,
  GET_CROP,
  GET_VARIETY,
  GET_ALL_USERS,
  PROCESS_LIST,
  CHANGE_RAW_MATERIAL_STATUS,
  CHANGE_PASSWORD,
  PROJECT_LIST,
  PACKING_LIST,
  ADD_PACKING_DATA,
  GET_PACKING_DATA_BY_ID,
  DELETE_RAW_MATERIAL,
  DELETE_CROP_DETAIL_BY_ID,
  VARIETY_LIST,
  WEIGHT_LIST,
  GET_CROP_PACKING_SIZE_BY_ID,
  ADD_DISPATCH_DATA,
  GET_DISPATCH_DATA,
  LOT_NO_LIST,
  GET_PACKAGING_STOCK_DATA,
  EDIT_PACKAGING_STOCK_DATA,
  GET_LOTNO_BY_ID,
  ADD_UPDATE_PRODUCTION,
  GET_PRODUCTION_DATA,
  USER_DATA,
  ADD_INSPECTION_DATA,
  GET_PROODUCTION_DETAIL_BY_ID, ADD_SAMPLING_DATA, GET_SAMPLING_DATA, GET_INSPECTION_DATA, GET_TESTING_DATA, EDIT_CROPWISE_PACKANGING_MATERIAL, GET_CROPWISE_PACKANGING_MATERIAL, GET_ALL_ROLES, PACKING_MATERIAL_LIST, GET_CROP_STOCK, ADD_GLOBAL_CROP, GLOBAL_CROP_VARIETY, ADD_GLOBAL_PACKING_SIZE, GET_FARMER_DATA_ORGANIZER, GET_DEALER_PDF, GET_EXPENSE_DATA_BY_ID, LOT_NO_LIST_BY_PACKING_SIZE, GET_PACKING_SIZE_DATA, GET_PRODUCTION_DATA_BY_ID, GET_GTM_DATA, ADD_UPDATE_GTM_DATA, DELETE_GTM_DATA, ADD_ORDER_DISPATCH_DATA, DELETE_PRODUCTION_DETAIL_BY_ID, ADD_TEST_DETAILS, FETCH_TEST_DETAILS_BY_LOT_NO, DELETE_EMPLOYEE, UPDATE_PROCESS_DATA, UPDATE_TEST_RESULT, EDIT_LOT_NO_STOCK, DELETE_DISPATCH, GET_ALL_SALESMAN, GET_USER_LOCATION, RETURN_ORDER, RETURN_ORDER_LIST, ON_RETURN,
  REMENANT_LIST,
  ADD_REMENANT_DISPATCH,
  ADD_SPLIT_RAW_DATA,
  LOT_NO_MERGE,
  REVERT_DATA,
  LOT_NO_LIST_FOR_MERGE,
  ORDER_TRANSFER_LIST,
  ORDER_TRANSFER_APPROVE,
  ORDER_RETURN
} from '../config/ApiConstant';
import axiosInstance from '../config/axiosInstance';

export default class CommonService {
  // --------------------------------   START ATTENDANCE PAGE API CALL --------------------------  //

  //Get All Attendance Data
  getAttendanceData(startDate: any, endDate: any, project: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_ATTENDANCE_DATA +
        '?start_date=' +
        convertDateFrom(Number(startDate)) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(Number(endDate)) +
        ' 23:59:59' +
        '&project=' + project
      )
      .then((res) => res.data.data);
  }

  //Delete Dispatch
  deleteDispatch(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        DELETE_DISPATCH +
        '?id=' + id
      )
      .then((res) => res.data);
  }

  //Update Attendance Status
  updateAttendanceStatus(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + UPDATE_ATTENDANCE_STATUS, requestData)
      .then((res) => res);
  }

  // --------------------------------   END ATTENDANCE PAGE API CALL --------------------------  //

  // --------------------------------   START LEAVE MANAGEMENT PAGE API CALL --------------------------  //

  //Get All Leave Management Data
  getLeaveManagementData(startDate: any, endDate: any, status: any, leaveDay: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_LEAVE_DATA +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59' +
        '&status=' + status +
        '&leave_day=' + leaveDay
      )
      .then((res) => res.data.data);
  }

  //Approve Leave
  approveLeaveData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + CHANGE_LEAVE_STATUS, requestData)
      .then((res) => res.data);
  }

  //Edit lot number stock
  editLotNoStock(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + EDIT_LOT_NO_STOCK, requestData)
      .then((res) => res.data);
  }

  //Reject Leave
  rejectLeaveData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + CHANGE_LEAVE_STATUS, requestData)
      .then((res) => res.data);
  }

  // --------------------------------   END LEAVE MANAGEMENT PAGE API CALL --------------------------  //

  // --------------------------------   START DEALER PAGE API CALL --------------------------  //

  //Get All Dealer Data
  getDealerData(status: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_DEALER_DATA +
        '?status=' + status
      )
      .then((res) => res.data.data);
  }

  //Get All Dealer Data
  dealerList() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_DEALER_DATA + '?status=0'
      )
      .then((res) => res.data.data);
  }

  //Get Dealer data by Id
  getDealerDataById(dealerId: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_DEALER_BY_ID + '?id=' + dealerId)
      .then((res) => res.data.data);
  }

  //Get Lead Dealer data by Id
  getLeadDealerDataById(dealerId: any, type: string) {
    return axiosInstance
      .get(APP_BASE_URL + GET_DEALER_BY_ID + '?id=' + dealerId + "&type=" + type)
      .then((res) => res.data.data);
  }

  //Get Dealer data by Id
  getDealerPdf(dealerId: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_DEALER_PDF + '?id=' + dealerId)
      .then((res) => res.data.data);
  }

  // Delete Dealer
  deleteDealerData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + DELETE_DEALER_DATA, requestData)
      .then((res) => res);
  }

  // Add Dealer
  addDealerData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_DEALER_DATA, requestData)
      .then((res) => res);
  }

  // Edit Dealer
  editDealerData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + EDIT_DEALER_DATA, requestData)
      .then((res) => res);
  }

  // --------------------------------   END DEALER PAGE API CALL --------------------------  //

  // --------------------------------   START DEALER LEAD PAGE API CALL --------------------------  //

  //Get All Deader Lead Data
  getDealerLeadData() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_DEALER_LEAD_DATA
      )
      .then((res) => res.data.data);
  }

  //Approve Leave
  approveDealerLeadData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + APPROVE_DEALER_LEAD, requestData)
      .then((res) => res.data);
  }

  // --------------------------------   END DEALER LEAD PAGE API CALL --------------------------  //

  //Get GTM Data
  getGTMData(startDate: any, endDate: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_GTM_DATA +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59'
      )
      .then((res) => res.data.data);
  }

  // Add Update GTM Data
  addUpdateGTMData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_UPDATE_GTM_DATA, requestData)
      .then((res) => res.data);
  }

  // Delete GTM Data
  deleteGTMData(id: any) {
    return axiosInstance
      .get(APP_BASE_URL + DELETE_GTM_DATA + "/" + id)
      .then((res) => res.data);
  }


  // --------------------------------   START FARMER PAGE API CALL --------------------------  //

  //Get All Leave Management Data
  getFarmerData(startDate: any, endDate: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_FARMER_DATA +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59'
      )
      .then((res) => res.data.data);
  }

  getFarmerDataByOrganizer(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_FARMER_DATA_ORGANIZER +
        '?id=' + id
      )
      .then((res) => res.data.data);
  }

  //Add / Edit farmer
  addEditfarmerData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_EDIT_FARMER_DATA, requestData)
      .then((res) => res.data);
  }

  //Delete Farmer
  deleteFarmerData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + DELETE_FARMER_DATA, requestData)
      .then((res) => res);
  }

  //Get Farmer data by Id
  getFarmerDataById(farmerId: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_FARMER_DATA_BY_ID + '?id=' + farmerId)
      .then((res) => res.data.data);
  }

  // --------------------------------   END FARMER PAGE API CALL --------------------------  //

  // --------------------------------   START EXPENSE PAGE API CALL --------------------------  //

  //Get All Expense Data
  getExpenseData(startDate: any, endDate: any, status: any, user : any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_EXPENSE_DATA +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59' +
        '&status=' + status +'&user=' + user
      )
      .then((res) => res.data);
  }

  // Approve Expense Data
  approveExpenseData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + APPROVE_EXPENSE_DATA, requestData)
      .then((res) => res);
  }

  //Get All Expense Data
  getExpenseDataById(id: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_EXPENSE_DATA_BY_ID + '?id=' + id).then((res) => res.data.data);
  }

  // --------------------------------   END EXPENSE PAGE API CALL --------------------------  //

  // --------------------------------   START CROPS PAGE API CALL --------------------------  //

  //Get All Crops Data
  getCropsData(startDate: any, endDate: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_CROP_DATA +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59'
      )
      .then((res) => res.data.data);
  }

  //Get Crops Data By Id
  getCropsDataById(cropId: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_CROP_DATA_BY_ID + '?crop_id=' + cropId)
      .then((res) => res.data.data);
  }

  //Get Crops Packing sizes By Id
  getCropsPackingSizeById(cropId: any, varietyId: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_CROP_PACKING_SIZE_BY_ID + '?crop_id=' + cropId + "&variety_id=" + varietyId)
      .then((res) => res.data.data);
  }

  //Get Crops Data By Id
  getVarietyListByCrop(cropId: any, dealer: any) {
    return axiosInstance
      .get(APP_BASE_URL + VARIETY_LIST + '?id=' + cropId + "&dealer_id=" + dealer)
      .then((res) => res.data.data);
  }

  //Get Crops Data By Id
  getWeightListByVariety(varietyId: any) {
    return axiosInstance
      .get(APP_BASE_URL + WEIGHT_LIST + '?id=' + varietyId)
      .then((res) => res.data.data);
  }

  //Get Crops Data By Id
  getLotNoList(cropId: any, varietyId: any) {
    return axiosInstance
      .get(APP_BASE_URL + LOT_NO_LIST + '?variety_id=' + varietyId + '&crop_id=' + cropId)
      .then((res) => res.data.data);
  }

    //Get Crops Data By Id
    lotNoListForMerge(cropId: any, varietyId: any) {
      return axiosInstance
        .get(APP_BASE_URL + LOT_NO_LIST_FOR_MERGE + '?variety=' + varietyId + '&crop=' + cropId)
        .then((res) => res.data.data);
    }

  //Get Crops Data By Id
  lotNoStock() {
    return axiosInstance
      .get(APP_BASE_URL + LOT_NO_LIST)
      .then((res) => res.data.data);
  }

  //Get Crops Data By Id
  getLotNoListByPackingSize(cropId: any, varietyId: any, packing_size: any) {
    return axiosInstance
      .get(APP_BASE_URL + LOT_NO_LIST_BY_PACKING_SIZE + '?variety_id=' + varietyId + '&crop_id=' + cropId + '&packing_size=' + packing_size)
      .then((res) => res.data.data);
  }

  //Get Packing size by lotno
  getLotNoWisePackingSizeList(lotNo: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_LOTNO_BY_ID + '?lot_no=' + lotNo)
      .then((res) => res.data.data);
  }

  //Get Packing Data By Id
  getPackingDataById(id: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_PACKING_DATA_BY_ID + '?id=' + id)
      .then((res) => res.data.data);
  }

  // Add / Edit Crop Status
  addEditCropData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_EDIT_CROP_DATA, requestData)
      .then((res) => res.data);
  }

  // Add Packing Data
  addPackingData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_PACKING_DATA, requestData)
      .then((res) => res.data);
  }

  // Delete Crop Status
  deleteCropData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + DELETE_CROP_DATA, requestData)
      .then((res) => res);
  }

  // Delete Crop Status
  deleteCropDetailById(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + DELETE_CROP_DETAIL_BY_ID, requestData)
      .then((res) => res);
  }

  //Get All Crops Data
  getPackagingStockData() {
    return axiosInstance
      .get(APP_BASE_URL + GET_PACKAGING_STOCK_DATA)
      .then((res) => res.data.data);
  }

  //Edit Packaging Material Stock Data
  editPackagingMaterialStock(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + EDIT_PACKAGING_STOCK_DATA, requestData)
      .then((res) => res.data);
  }

  //Get All Crops Data
  getCropStockData() {
    return axiosInstance
      .get(APP_BASE_URL + GET_CROP_STOCK)
      .then((res) => res.data.data);
  }

  // --------------------------------   END CROPS PAGE API CALL --------------------------  //

  // --------------------------------   START PRODUCT PAGE API CALL --------------------------  //

    //Get All Product Data
    getProductRawMatrialData(type : any) {
      return axiosInstance.get(APP_BASE_URL + GET_RAW_MATERIAL_LIST + "?type=" + type).then((res) => res.data);
    }

    // Add Row Matrial Slip Status
    addRawMatrialSlipData(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + RAW_MATERIAL_SLIP, requestData)
        .then((res) => res.data);
    }

    // Add After Row Matrial Data
    addAfterRawmaterial(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + ADD_AFTER_RAWMATERIAL_DATA, requestData)
        .then((res) => res.data);
    }

    // Add After Row Matrial Data
    deleteRawMaterial(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + DELETE_RAW_MATERIAL, requestData)
        .then((res) => res.data);
    }


    // Add doctor test data
    addTestData(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + ADD_TEST_DETAILS, requestData)
        .then((res) => res.data);
    }

    // Add doctor test data
    updateTestResult(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + UPDATE_TEST_RESULT, requestData)
        .then((res) => res.data);
    }


    fetchTestDetails(requestData: any) {
      return axiosInstance
        .get(APP_BASE_URL + FETCH_TEST_DETAILS_BY_LOT_NO + "?lot_no=" + requestData).then((res) => res.data.data);
    }

    // Add After Row Matrial Data
    addSplitRawData(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + ADD_SPLIT_RAW_DATA, requestData)
        .then((res) => res.data);
    }

    // Add lot no merge Data
    addLotNoMerge(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + LOT_NO_MERGE, requestData)
        .then((res) => res.data);
    }
  // --------------------------------   END PRODUCT PAGE API CALL --------------------------  //


  // --------------------------------   START AGENT PAGE API CALL --------------------------  //

  //Get All Agent Data
  getAgentData(list: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_AGENT +
        '?list=' + list)
      .then((res) => res.data.data);
  }

  //Get All Agent Data
  agentDropdown() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_AGENT
      )
      .then((res) => res.data.data);
  }

  // add update agent 
  addUpdateAgentData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_UPDATE_AGENT, requestData)
      .then((res) => res.data);
  }

  // Delete agent
  deleteAgentData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + DELETE_AGENT, requestData)
      .then((res) => res);
  }

  // get agent by id
  getAgentById(agentId: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_AGENT_BY_ID + "?id=" + agentId).then((res) => res.data.data);
  }

  // --------------------------------   END AGENT PAGE API CALL --------------------------  //


  // --------------------------------  START ORDER PAGE API CALL --------------------------  //

  //Get All order list
  getOrderData(startDate: any, endDate: any, status: any, dealer: any, role: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_ORDER_LIST +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59' +
        '&status=' + status +
        '&dealer=' + dealer +
        '&role=' + role
      )
      .then((res) => res.data.data);
  }

  // add update agent 
  addUpdateOrder(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_UPDATE_ORDER, requestData)
      .then((res) => res.data);
  }

  // Delete agent
  deleteOrder(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + DELETE_ORDER, requestData)
      .then((res) => res);
  }

  // get agent by id
  getOrderById(orderId: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_ORDER_BY_ID + "?id=" + orderId).then((res) => res.data.data);
  }

  approveOrder(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + APPROVE_ORDER, requestData).then((res) => res.data);
  }

  orderTransferList(type : any) {
    return axiosInstance
      .get(APP_BASE_URL + ORDER_TRANSFER_LIST + "?type=" + type )
      .then((res) => res.data.data);
  }

  orderTransferApprove(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ORDER_TRANSFER_APPROVE, requestData).then((res) => res.data);
  }

  orderReturnQty(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ORDER_RETURN, requestData).then((res) => res.data);
  }
  // --------------------------------  END ORDER PAGE API CALL --------------------------  //


  // --------------------------------   COMMON API CALL --------------------------  //

  getState() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_STATE
      )
      .then((res) => res.data.data);
  }

  getCity(state: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_CITY + "?state_id=" + state).then((res) => res.data.data);
  }

  //Get All Crops Data
  getCropList(dealer: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_CROP_DATA + "?dealer_id=" + dealer
      )
      .then((res) => res.data.data);
  }

  // add update agent 
  addEmployee(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_EMPLOYEE, requestData)
      .then((res) => res.data);
  }

  // Delete GTM Data
  deleteEmployee(id: any) {
    return axiosInstance
      .delete(APP_BASE_URL + DELETE_EMPLOYEE + "/" + id)
      .then((res) => res.data);
  }

  cropList() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_CROP
      )
      .then((res) => res.data.data);
  }

  // add update agent 
  addGlobalCrop(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_GLOBAL_CROP, requestData)
      .then((res) => res.data);
  }

  // add update agent 
  addGlobalPackingSize(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_GLOBAL_PACKING_SIZE, requestData)
      .then((res) => res.data);
  }

  globalCropVariety() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GLOBAL_CROP_VARIETY
      )
      .then((res) => res.data.data);
  }

  varietyList(crop: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_VARIETY + "?crop_id=" + crop).then((res) => res.data.data);
  }

  userList() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_ALL_USERS
      )
      .then((res) => res.data.data);
  }

  userData(id: any) {
    return axiosInstance
      .get(APP_BASE_URL + USER_DATA + "?id=" + id).then((res) => res.data.data);
  }

  projectList() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        PROJECT_LIST
      )
      .then((res) => res.data.data);
  }

  //Function For Change Password
  changePassword(formRequestData: any) {
    return axiosInstance.post(APP_BASE_URL + CHANGE_PASSWORD, formRequestData).then((res) => res.data);
  }

  // get agent by id
  getPackingSizeData(crop_id: any, variety_id: any, packing_size: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_PACKING_SIZE_DATA + "?crop_id=" + crop_id + "&variety_id=" + variety_id + "&packing_size=" + packing_size).then((res) => res.data.data);
  }
  // --------------------------------   COMMON API CALL --------------------------  //

  // --------------------------------   REMENANT API CALL --------------------------  //
    //Get All Remenant Data
    remenantList() {
      return axiosInstance
        .get(
          APP_BASE_URL +
          REMENANT_LIST
        )
        .then((res) => res.data.data);
    }

    // Add Remenant Dispatch Data
    addRemenantDispatchData(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + ADD_REMENANT_DISPATCH, requestData)
        .then((res) => res.data);
    }
  // --------------------------------   REMENANT API CALL --------------------------  //

  // --------------------------------   PROCESS API CALL --------------------------  //

    //Get All Product Data
    processList(startDate: any, endDate: any, processStatus: any, year : any, crop : any) {
      return axiosInstance
        .get(
          APP_BASE_URL +
          PROCESS_LIST +
          '?start_date=null' +
          '&end_date=null' +
          '&process_status=' + processStatus + '&year=' + year + '&crop=' + crop
        )
        .then((res) => res.data.data);
    }

    getProcessCSVData() {
      return axiosInstance.get(APP_BASE_URL +
        PROCESS_LIST).then((res) => res.data.csv_data);
    }

    changeProcessStatus(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + CHANGE_RAW_MATERIAL_STATUS, requestData)
        .then((res) => res.data);
    }

    // update process data
    updateProcessData(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + UPDATE_PROCESS_DATA, requestData)
        .then((res) => res.data);
    }

    // revrt data
    revertData(requestData: any) {
      return axiosInstance
        .post(APP_BASE_URL + REVERT_DATA, requestData)
        .then((res) => res.data);
    }
  // --------------------------------   PROCESS API CALL --------------------------  //

  // --------------------------------   PACKING API CALL --------------------------  //
  //Get All Packing Data
  packinglist() {
    return axiosInstance
      .get(
        APP_BASE_URL +
        PACKING_LIST
      )
      .then((res) => res.data);
  }

  //Get All Packing Data
  packingMateriallist(startDate: any, endDate: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        PACKING_MATERIAL_LIST +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59'
      )
      .then((res) => res.data);
  }

  // --------------------------------   PACKING API CALL --------------------------  //

  // --------------------------------   DISPATCH API CALL --------------------------  //

  // Add Dispatch Data
  addDispatchData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_DISPATCH_DATA, requestData)
      .then((res) => res.data);
  }

  // Add Dispatch Data
  addOrderDispatchData(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + ADD_ORDER_DISPATCH_DATA, requestData)
      .then((res) => res.data);
  }

  // Get Dispatch Data
  getDispatchData() {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_DISPATCH_DATA
      )
      .then((res) => res.data);
  }

  // Get Dispatch Data
  getDispatchDataById(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_DISPATCH_DATA + "?id=" + id
      )
      .then((res) => res.data.data);
  }

  // return order
  returnOrder(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + RETURN_ORDER, requestData)
      .then((res) => res.data);
  }

  //Get All Agent Data
  returOrderList(startDate: any, endDate: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        RETURN_ORDER_LIST +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59'
      )
      .then((res) => res.data.data);
  }

  //Get All Agent Data
  onClickReturn(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        ON_RETURN +
        '?id=' + id
      )
      .then((res) => res.data.data);
  }
  // --------------------------------   DISPATCH API CALL --------------------------  //

  // -------------------------------- START PRODUCTION API CALL --------------------------  //
  agentList(roleId: any) {
    return axiosInstance
      .get(
        APP_BASE_URL +
        GET_ALL_USERS + "?role_id=" + roleId
      )
      .then((res) => res.data.data);
  }

  addUpdateProduction(requestData: any) {
    return axiosInstance.post(APP_BASE_URL + ADD_UPDATE_PRODUCTION, requestData)
      .then((res) => res.data);
  }

  // Get Dispatch Data
  getProductionData() {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_PRODUCTION_DATA
      )
      .then((res) => res.data.data);
  }

  getCSVProductionData(productionId: any, crop_id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_PRODUCTION_DATA + "?production_id=" + productionId + "&crop_id=" + crop_id
      )
      .then((res) => res.data);
  }

  // Get production Data by id
  getProductionDetails(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_PRODUCTION_DATA + "?id=" + id
      )
      .then((res) => res.data.data);
  }

  // Get production Data by id
  getProductionDataById(id: any, crop_id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_PRODUCTION_DATA_BY_ID + "?id=" + id + "&crop_id=" + crop_id
      )
      .then((res) => res.data.data);
  }

  getProductionDetailById(id: any) {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_PROODUCTION_DETAIL_BY_ID + "?id=" + id
      )
      .then((res) => res.data.data);
  }

  // Delete Crop Status
  deletetProductionDetailById(requestData: any) {
    return axiosInstance
      .post(APP_BASE_URL + DELETE_PRODUCTION_DETAIL_BY_ID, requestData)
      .then((res) => res);
  }


  addFieldInspectionData(requestData: any) {
    return axiosInstance.post(APP_BASE_URL + ADD_INSPECTION_DATA, requestData)
      .then((res) => res.data);
  }

  addSampleData(requestData: any) {
    return axiosInstance.post(APP_BASE_URL + ADD_SAMPLING_DATA, requestData)
      .then((res) => res.data);
  }

  samplingReportData(productionId: any, cropId: any) {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_SAMPLING_DATA + "?production_id=" + productionId + "&crop_id=" + cropId
      )
      .then((res) => res.data);
  }

  inspectionReportData(productionId: any, startDate: any, endDate: any, cropId: any) {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_INSPECTION_DATA + "?production_id=" + productionId +
        '&start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59&crop_id=' + cropId
      )
      .then((res) => res.data);
  }

  testingReportData(startDate: any, endDate: any) {
    return axiosInstance
      .get(
        APP_BASE_URL + GET_TESTING_DATA +
        '?start_date=' +
        convertDateFrom(startDate) +
        ' 00:00:00' +
        '&end_date=' +
        convertDateFrom(endDate) +
        ' 23:59:59'
      )
      .then((res) => res.data);
  }

  editCropWisePackagingMaterial(requestData: any) {
    return axiosInstance.post(APP_BASE_URL + EDIT_CROPWISE_PACKANGING_MATERIAL, requestData)
      .then((res) => res.data);
  }

  getCropWisePackagingMaterial() {
    return axiosInstance.get(APP_BASE_URL + GET_CROPWISE_PACKANGING_MATERIAL)
      .then((res) => res.data.data);
  }

  // -------------------------------- END PRODUCTION API CALL --------------------------  //

  // -------------------------------- START LOCATION API CALL --------------------------  //
  salesmanList() {
    return axiosInstance
      .get(APP_BASE_URL + GET_ALL_SALESMAN).then((res) => res.data.data);
  }

  userLocation(userId: any, date: any) {
    return axiosInstance
      .get(APP_BASE_URL + GET_USER_LOCATION + '?user_id=' + userId + '&date=' +
        convertDateFrom(date)).then((res) => res.data.data);
  }

  // -------------------------------- END LOCATION API CALL --------------------------  //


  getAllRoles() {
    return axiosInstance
      .get(APP_BASE_URL + GET_ALL_ROLES)
      .then((res) => res.data.data);
  }
}
