import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { formatDateTime } from '../../appconfig/AppHelper';
import { CSVLink } from "react-csv";
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';


// Column
import { ProcessColumns, ProcessViewColumns } from '../../appconfig/DatatableSetting';

// Data table
import { defaultPageRowOptions, defaultRowOptions, paginatorLinks, showingEntries, SkeletonbodyTemplate, Skeletonitems, processStatusDropdown, cropDivisionDropdownItems } from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

// Validatetion
import { MergeValidate, ProcessingValidate, RevertValidate } from '../../config/Validate';
import { ProgressSpinner } from 'primereact/progressspinner';

export const Process = () => {
  document.title = 'Process | Swarnim Farms';

  const role = localStorage.hasOwnProperty('role')
    ? localStorage.getItem('role')
    : null;

  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [csvData, setCsvData] = useState([]);

  //Product Crud Object
  const [products, setProducts] = useState<any>([]);
  const [processData, setProcessData] = useState<any>({});

  // add dialog
  const [approveConfirmModal, setApproveConfirmModal] = useState<boolean>(false);

  // set Raw material id
  const [rawId, setRawId] = useState(null);

  // Error object
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});

  const [spinner, setSpinner] = useState<boolean>(false);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  const [viewProductDialog, setViewProductDialog] = useState<boolean>(false);
  const [viewProductData, setViewProductData] = useState<any>({});
  const [viewProcessData, setViewProcessData] = useState<any>({});

  const [processStatus, setProcessStatus] = useState<any>({ name: 'All', code: 'All' });

  const [editId, setEditId] = useState<any>(null);
  const [editData, setEditData] = useState<any>({});

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);

  // crop and variety
  const [cropData, setCropData] = useState<any>([]);
  const [selectedCropId, setSelectedCropId] = useState<any>(null);
  const [selectedVarietyId, setSelectedVarietyId] = useState<any>(null);
  const [varietyData, setVarietyData] = useState<any>([]);
  const [lotNoList, setLotNoList] = useState<any>([]);
  const [selectedLotNo, setSelectedLotNo] = useState<any>([]);
  const [newLotNo, setNewLotNo] = useState<any>(null);
  const [revertData, setRevertData] = useState<any>({});
  const [packingSizeDropdown, setPackingSizeDropdown] = useState<any>([]);
  const [mergeType, setMergeType] = useState<any>('New');
  const [existingLotNo, setExistingLotNo] = useState<any>(null);
  const [lotNoDetailsArr, setLotNoDetailsArr] = useState<any>([{
    lot_no: '',
    crop_qnty: '',
  }]);
  const [uiUpdated, setIsUiUpdated] = useState<boolean>(false);
  const [mergeLotNoList, setMergeLotNoList] = useState<any>([]);

  const [mergeDialog, setMergeDialog] = useState<boolean>(false);
  const [revertDialog, setRevertDialog] = useState<boolean>(false);

  const [cropDivisionDropdownItem, setCropDropdownItem] = useState<any>(null);

  let date = new Date().getFullYear();

  const [year, setYear] = useState<any>(new Date());
  const [selectedCrop, setSelectedCrop] = useState<any>({ name: 'All', code: 'All' });

  // use effect method
  useEffect(() => {
    getData();
    getCropList();
  }, [year, processStatus, selectedCrop]);

  useEffect(() => {
    if (csvData.length > 0) {
      csvLink?.current?.link.click();
      setCsvData([]);
    }
  }, [csvData]);

  useEffect(() => {
    if (uiUpdated) {
      setIsUiUpdated(false);
    }
  }, [lotNoDetailsArr, uiUpdated]);

  // get raw matrail product data
  const getData = async () => {
    setLoading(true);

    // Api call
    pageService
      .processList(
        "null", "null", processStatus?.name, year.getFullYear(), selectedCrop?.code)
      .then((response) => {
        // Get response
        if (response) {
          const productData = response;
          if (productData.length == 0) {
            setProducts([]);
            setErrors(false);
            setLoading(false);
            setPageLoad(true);
          } else {
            setProducts(productData);
            setErrors(false);
            setLoading(false);
            setPageLoad(true);
          }
        } else {
          setProducts([]);
          setErrors(false);
          setLoading(false);
          setPageLoad(false);
        }
      });
  };

  // Get crops
  const getCropList = async () => {
    try {
      const cropData: any = [];

      pageService.cropList().then((response) => {
        if (response) {
          const cropDataList = response;
          for (let i = 0; i < cropDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = cropDataList[i].name;
            userObj['code'] = cropDataList[i].id;
            cropData.push(userObj);
          }
          setCropData(cropData);
        }
      });

    } catch (error) { }
  };

  //Get City
  const varietyList = async (selectedCropId: any, extra: string) => {
    try {
      let cropId = !window.cn(selectedCropId) ? selectedCropId.code : '';
      const varietyData: any = [];
      pageService.varietyList(cropId).then((response) => {
        if (response) {
          const varietyDataList = response;
          for (let i = 0; i < varietyDataList.length; i++) {
            const varietyObj: any = {};
            varietyObj['name'] = varietyDataList[i].name;
            varietyObj['code'] = varietyDataList[i].id;
            varietyData.push(varietyObj);
          }
          setVarietyData(varietyData);
        }
      });
    } catch (error) { }
  };

  const getLotNoList = async (crop_id: any, variety_id: any) => {

    pageService.lotNoListForMerge(crop_id.code, variety_id.code).then((response) => {
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setLotNoList([]);
        } else {
          if (DataList) {
            setLotNoList(DataList);
          }
        }
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  //onChange proccessing data
  const onInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setProcessData({ ...processData, [name]: val.toUpperCase() });
  };

  //onChange proccessing data
  const onEditInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setEditData({ ...editData, [name]: val.toUpperCase() });
  };

  // add processing data
  const addProcessData = async () => {
    const { errors, isError } = ProcessingValidate(processData);
    setErrors(errors);
    setIsError(isError);

    if (!isError) {
      setLoading(true);

      // request data
      let requestData = processData;
      requestData['weight_in'] = viewProductData?.weight_in;
      requestData["raw_id"] = rawId;
      // requestData["status"] = 2;
      requestData["net_processed_qnty"] = requestData["processed_qnty"] - requestData["remenant"] - requestData["losses"];

      // call api
      pageService.changeProcessStatus(requestData).then((response) => {
        setProcessData({});
        setErrors(false);
        setLoading(false);
        setApproveConfirmModal(false);
        setViewProductData({});
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        });
        getData();
      }).catch(error => {
        setLoading(false);
        setErrors(false);
        setApproveConfirmModal(true);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
      });
    }
  };

  //Approve Data
  const approveData = (rowData: any) => {
    setRawId(rowData.id);
    setViewProductData(rowData);
    setApproveConfirmModal(true);
  };

  //hide Approve Modal close
  const hideApproveConfirmModal = () => {
    setRawId(null);
    setApproveConfirmModal(false);
    setProcessData({});
    setErrors(false);
    setViewProductData({});
  };

  const mergeData = () => {
    setSpinner(true);
    setMergeDialog(true);
    setSpinner(false);
  }

  // close merge dialog
  const hideMergeDialog = () => {
    setMergeDialog(false);
    setSelectedCropId(null);
    setSelectedLotNo([]);
    setSelectedVarietyId(null);
    setVarietyData([]);
    setLotNoList([]);
    setNewLotNo(null);
    setErrors({});
    setMergeType('New');
    setLotNoDetailsArr([{
      lot_no: '',
      crop_qnty: '',
    }])
    setIsUiUpdated(true)
  };

  // Handle Select Crop
  const handleSelectedCrop = (value: any) => {
    if (!window.cn(value)) {
      setSelectedCropId(value);
      varietyList(value, 'null');
      setLotNoList([]);
      setSelectedLotNo(null)
    } else {
      setSelectedCropId('');
      setVarietyData([]);
      setLotNoList([]);
      setExistingLotNo(null);
      setLotNoDetailsArr([{
        lot_no: '',
        crop_qnty: '',
      }])
      setIsUiUpdated(true)
    }
  };

  // Handle Select Crop
  const handleSelectedVariety = (value: any) => {
    if (!window.cn(value)) {
      setSelectedVarietyId(value);
      getLotNoList(selectedCropId, value)
      setExistingLotNo(null);
      setLotNoDetailsArr([{
        lot_no: '',
        crop_qnty: '',
      }])
      setIsUiUpdated(true)
    } else {
      setSelectedVarietyId('');
      setLotNoList([]);
      setExistingLotNo(null);
      setLotNoDetailsArr([{
        lot_no: '',
        crop_qnty: '',
      }])
      setIsUiUpdated(true)
    }
  };

  const onHandleChange = (e: any, index: number, name: string) => {
    let tempArray = lotNoDetailsArr;

    if (name == 'lot_no') {
      tempArray[index][name] = e;
    } else {
      tempArray[index][name] = e.target.value;
    }
    setLotNoDetailsArr(tempArray);
    setIsUiUpdated(true);
  }

  //handle add more
  const handleAddMore = () => {
    setLotNoDetailsArr([...lotNoDetailsArr, {
      lot_no: '',
      crop_qnty: '',
    }]);
    setIsUiUpdated(true)
  }

  //delete merge add mor detail
  const deleteAddMore = (index: number) => {
    lotNoDetailsArr.splice(index, 1);
    setIsUiUpdated(true);
  }

  //handle change for existing Lot No
  const onHandleExistingLotNo = (e: number) => {
    setExistingLotNo(e);
    setMergeLotNoList((lotNoList.filter((data: any) => {
      return data.value != e;
    })))
  }

  // add merge data
  const onMergeData = async () => {
    const { errors, isError } = MergeValidate(newLotNo, selectedCropId, selectedVarietyId, selectedLotNo, mergeType, cropDivisionDropdownItem, existingLotNo, lotNoDetailsArr);
    setErrors(errors);
    setIsError(isError);

    if (!isError) {
      setLoading(true);

      let formData = new FormData();

      formData.append('crop', selectedCropId.code);
      formData.append('variety', selectedVarietyId.code);
      formData.append('merge_type', mergeType);

      if (!window.cn(mergeType) && mergeType == 'New') {
        formData.append('lot_no_to_merge', selectedLotNo);
        formData.append('lot_no', newLotNo);
        formData.append('crop_division', cropDivisionDropdownItem.code);
      } else {
        var lotNoToMerge: any = [];

        lotNoDetailsArr.map((value: any, index: number) => {
          lotNoToMerge.push(value.lot_no);
        });
        formData.append('lot_no_to_merge', lotNoToMerge);
        formData.append('merge_lot_no_details', JSON.stringify(lotNoDetailsArr));
        formData.append('merge_to_existing_lot_no', existingLotNo);
      }

      // Api call
      pageService.addLotNoMerge(formData).then((response) => {
        // Get Response
        setLoading(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        });
        setMergeDialog(false);
        setSelectedCropId(null);
        setSelectedLotNo([]);
        setSelectedVarietyId(null);
        setVarietyData([]);
        setLotNoList([]);
        setNewLotNo(null);
        setMergeType('New');
        getData();
        setExistingLotNo(null);
        setLotNoDetailsArr([{
          lot_no: '',
          crop_qnty: '',
        }])
      }).catch(error => {
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
      });
    }
  };

  // Handle Select Crop
  const onRevertHandleChange = (value: any, name: any) => {
    setRevertData({ ...revertData, [name]: value })
  };

  const revert = (rowData: any) => {
    setSpinner(true);
    setRevertDialog(true);
    setRevertData({ ...revertData, rowData });
    getWeightList(rowData.variety)
    setSpinner(false);
  }

  // close split dialog
  const hideRevertDialog = () => {
    setRevertDialog(false);
    setRevertData({});
    setErrors({});
    setPackingSizeDropdown([]);
  };

  const getWeightList = async (id: any) => {

    pageService.getWeightListByVariety(id).then((response) => {
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setPackingSizeDropdown([]);
        } else {
          if (response) {
            const DataList = response;
            let tempWeightArray: any = [];
            for (let i = 0; i < DataList.length; i++) {
              let weightObj: any = {};
              weightObj['name'] = DataList[i].name;
              weightObj['code'] = DataList[i].name;
              tempWeightArray.push(weightObj);
            }
            setPackingSizeDropdown(tempWeightArray);
          }
        }
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  // add merge data
  const onRevertData = async () => {
    const { errors, isError } = RevertValidate(revertData);
    setErrors(errors);
    setIsError(isError);

    if (!isError) {
      setLoading(true);

      let formData = new FormData();

      formData.append('no_of_packets', revertData.no_of_packets);
      formData.append('packing_size', revertData.packing_size.code);
      formData.append('crop', revertData.rowData.crop)
      formData.append('variety', revertData.rowData.variety)
      formData.append('lot_no', revertData.rowData.lot_no)

      // Api call
      pageService.revertData(formData).then((response) => {
        // Get Response
        setLoading(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        });
        setRevertDialog(false);
        setRevertData({});
        setErrors({});
        setPackingSizeDropdown([]);
        getData();
      }).catch(error => {
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
      });
    }
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <>
        <div className="actions" style={{ display: 'flex' }}>
          <Button
            icon="pi pi-eye"
            tooltip='View'
            tooltipOptions={{ position: 'bottom' }}
            className="p-button-rounded p-button-primary mt-2 mr-3 mb-2"
            onClick={() => viewRawMaterailData(rowData)}
          />
          <Button
            icon="pi pi-replay"
            tooltip='Revert'
            tooltipOptions={{ position: 'bottom' }}
            className="p-button-rounded p-button-primary mt-2 mb-2"
            onClick={() => revert(rowData)}
            disabled={rowData.packing.length == 0 || rowData.process.length == 0}
          />
        </div>
      </>
    );
  };

  // for column action
  const statusBodyTemplate = (rowData: any) => {
    return (
      <Button
        icon="pi pi-plus"
        tooltip='Add'
        tooltipOptions={{ position: 'bottom' }}
        className="p-button-rounded p-button-primary mt-2 mb-2"
        style={{ marginRight: '17px' }}
        onClick={(e) => approveData(rowData)}
        disabled={rowData.status == 2}
      />
    );
  };

  const cropBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData.crop_obj?.name}</div>
      </>
    )
  };

  const varietyBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData.variety_obj?.name}</div>
      </>
    )
  };

  const qtyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.crop_qnty + " " + rowData?.weight_in}</div>
      </>
    )
  };

  const viewQtyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.processed_qnty + " " + rowData?.weight_in}</div>
      </>
    )
  };

  //download csv
  const exportCSV = async () => {
    setCsvLoading(true);
    //Validation Chec
    pageService
      .getProcessCSVData()
      .then((response) => {
        // get response
        if (response) {
          let csvDetails = response;
          if (csvDetails.length > 0) {
            setCsvData(csvDetails);
            setCsvLoading(false);
          }
        } else {
          setCsvData([]);
        }
      });
  }

  // for left toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // for right toolbar
  const rightToolbarTemplate = () => {
    return (
      <>

        Year :
        <Calendar
          id="yearpicker"
          value={year}
          onChange={(e) => setYear(e.value)}
          view="year"
          dateFormat="yy"
        />
        <Dropdown
          filter
          value={selectedCrop}
          onChange={(e) => setSelectedCrop(e.value)}
          options={cropData}
          optionLabel="name"
          name="crop"
          placeholder="Select Crop"
        ></Dropdown>
        <Dropdown
          id="process_status"
          value={processStatus}
          onChange={(e) => setProcessStatus(e.value)}
          options={processStatusDropdown}
          optionLabel="name"
          placeholder="Process Status"
          name="process_status"
          className='mr-2'
        ></Dropdown>
        <Button
          label="Merge Lot"
          icon="pi pi-plus"
          className="p-button-success mr-2 ml-2"
          onClick={mergeData}
        />
        <Button
          label="Export"
          icon="pi pi-file-export"
          className="p-button-success mr-2 ml-2"
          loading={csvLoading}
          onClick={exportCSV} />
        <CSVLink
          data={csvData}
          filename='Processing_list.csv'
          className='hidden'
          ref={csvLink}
          target='_blank'
        />
      </>
    );
  };

  const viewRawMaterailData = (rowData: any) => {
    setSpinner(true);
    setViewProductDialog(true);
    setViewProductData(rowData);
    setViewProcessData(rowData.process);
    setSpinner(false);
  }

  // close view orders data dialog
  const hideProductDialog = () => {
    setViewProductDialog(false);
    setViewProductData({});
    setSpinner(false);
    setEditId(null);
    setEditData({});
  };

  const processDateTemplate = (rowData: any) => {
    return (<>
      <div>{!window.cn(rowData) && rowData.process != null ? formatDateTime(rowData?.created_at) : "-"}</div>
    </>);
  };

  const DateTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };

  const farmerTemplate = (rowData: any) => {
    return (
      <>
        <div>{!window.cn(rowData.farmer_name) ? rowData?.farmer_name : "-"}</div>
      </>
    )
  };

  const classTemplate = (rowData: any) => {
    return (
      <>
        <div>{!window.cn(rowData.crop_division) ? rowData?.crop_division : "-"}</div>
      </>
    )
  };

  // for column action
  const viewActionTemplate = (rowData: any) => {
    return (
      <>
        {!window.cn(role) && role == '1' ?
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-primary mt-2"
            style={{ marginRight: '17px' }}
            onClick={(e) => editProcessData(rowData)}
            disabled={rowData.edit_status == 0}
          /> : '-'}</>
    );
  };

  const editProcessData = (rowData: any) => {
    setEditId(rowData.id);
    setEditData(rowData);
  }

  const onsubmitEdit = async () => {

    const { errors, isError } = ProcessingValidate(editData);
    setErrors(errors);
    setIsError(isError);

    if (!isError) {
      setLoading(true);

      // request data
      let formRequestData = new FormData();

      formRequestData.append('id', editId);
      formRequestData.append('raw_id', editData?.raw_id);
      formRequestData.append('processed_qnty', editData?.processed_qnty);
      formRequestData.append('remenant', editData?.remenant);
      formRequestData.append('losses', editData?.losses);
      let netProcessedQty = editData["processed_qnty"] - editData["remenant"] - editData["losses"]
      formRequestData.append('net_processed_qnty', netProcessedQty.toString());

      // call api
      pageService.updateProcessData(formRequestData).then((response) => {
        setEditData({});
        setEditId(null);
        setErrors(false);
        setLoading(false);
        setViewProductDialog(false);
        setViewProductData({});
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        });
        getData();
      }).catch(error => {
        setLoading(false);
        setErrors(false);
        setViewProductDialog(true);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
      });
    }
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h4>Processing List</h4>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>

            {/* Datatable Start */}
            {pageLoad ? (
              <>
                <DataTable
                  value={products}
                  loading={loading}
                  paginator={products.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  className="datatable-responsive p-datatable-sm"
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Processing Data Found"
                >
                  {ProcessColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'status') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={statusBodyTemplate}
                        />
                      );
                    } else if (col.field === 'crop_division') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={classTemplate}
                        />
                      );
                    } else if (col.field === 'crop_qnty') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={qtyTemplate}
                        />
                      );
                    } else if (col.field === 'farmer_name') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={farmerTemplate}
                        />
                      );
                    } else if (col.field === 'process') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={processDateTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'crop') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={cropBodyTemplate}
                        />
                      );
                    } else if (col.field === 'variety') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={varietyBodyTemplate}
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {ProcessColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
            {/* Datatable End */}

            {/* View Dialog start*/}
            <Dialog
              visible={viewProductDialog}
              style={{ width: '650px' }}
              header="View Product Data"
              modal
              footer
              onHide={hideProductDialog}
            >

              <div className="formgrid grid">

                <div className="field col">
                  <p> <b>Farmer Name : </b>  {!window.cn(viewProductData.farmer_name) ? viewProductData.farmer_name : ""}</p>
                </div>
                <div className="field col">
                  <p><b>Production Location</b> : {!window.cn(viewProductData.location) ? viewProductData.location : ""}</p>
                </div>
                <div className="field col">
                  <p> <b>Lot No.</b> : {!window.cn(viewProductData.lot_no) ? viewProductData.lot_no : ""}</p>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <p> <b>Crop</b> : {!window.cn(viewProductData.crop) ? viewProductData.crop_obj?.name : ""}</p>
                </div>
                <div className="field col">
                  <p> <b>Variety</b> : {!window.cn(viewProductData.variety) ? viewProductData.variety_obj?.name : ""}</p>
                </div>
                <div className="field col">
                  <p> <b>Class</b> : {!window.cn(viewProductData.crop_division) ? viewProductData.crop_division : ""}</p>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <p> <b>District</b> : {!window.cn(viewProductData.district) ? viewProductData.district : ""}</p>
                </div>
                <div className="field col">
                  <p> <b>Taluka</b> : {!window.cn(viewProductData.taluka) ? viewProductData.taluka : ""}</p>
                </div>
                <div className="field col">
                  <p><b> Village</b> : {!window.cn(viewProductData.village) ? viewProductData.village : ""}</p>
                </div>
              </div>
              <hr />
              <DataTable
                value={viewProcessData}
                loading={loading}
                rows={defaultRowOptions}
                className="datatable-responsive"
                emptyMessage="No Processing Data Found"
              >
                {ProcessViewColumns.map((col, i) => {
                  if (col.field === 'sr_no') {
                    return (
                      <Column
                        key={col.field}
                        header={col.header}
                        body={(_, { rowIndex }) => rowIndex + 1} />
                    );
                  } else if (col.field === 'processed_qnty') {
                    return (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        body={viewQtyTemplate}
                      />
                    );
                  } else if (col.field === 'created_at') {
                    return (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        body={DateTemplate}
                      />
                    );
                  } else if (col.field === 'edit_status') {
                    return (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        body={viewActionTemplate}
                      />
                    );
                  } else {
                    return (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                      />
                    );
                  }
                })}
              </DataTable>

              {editId != null ?
                <>
                  <hr />
                  <div className="formgrid grid">
                    <div className="field col-3">
                      <label htmlFor="processed_qnty">Processed Quantity *</label>
                      <InputText
                        keyfilter="num"
                        value={!window.cn(editData.processed_qnty) ? editData.processed_qnty : ""}
                        name="processed_qnty"
                        autoComplete="off"
                        onChange={(e) => onEditInputChange(e, 'processed_qnty')}
                        className={errors['processed_qnty'] && 'p-invalid'}
                      />
                      <small className="p-invalid-txt">{errors['processed_qnty']}</small>
                    </div>
                    <div className="field col-3">
                      <label htmlFor="wight">Weight *</label>
                      <InputText
                        value={!window.cn(viewProductData.weight_in) ? viewProductData.weight_in : ""}
                        name="weight"
                        autoComplete="off"
                        disabled
                        readOnly
                      />
                    </div>
                    <div className="field col-3">
                      <label htmlFor="remenant">Remenant *</label>
                      <InputText
                        keyfilter="num"
                        value={!window.cn(editData.remenant) ? editData.remenant : ""}
                        name="remenant"
                        autoComplete="off"
                        onChange={(e) => onEditInputChange(e, 'remenant')}
                        className={errors['remenant'] && 'p-invalid'}
                      />
                      <small className="p-invalid-txt">{errors['remenant']}</small>
                    </div>
                    <div className="field col-3">
                      <label htmlFor="losses">Processed Loss *</label>
                      <InputText
                        keyfilter="num"
                        value={!window.cn(editData.losses) ? editData.losses : ""}
                        name="losses"
                        autoComplete="off"
                        onChange={(e) => onEditInputChange(e, 'losses')}
                        className={errors['losses'] && 'p-invalid'}
                      />
                      <small className="p-invalid-txt">{errors['losses']}</small>
                    </div>
                  </div>
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={onsubmitEdit}
                    loading={loading}
                  />
                </> : ""}

              {
                spinner && <div className="loading flex justify-content-center">
                  <ProgressSpinner />
                </div>
              }
            </Dialog>
            {/* View Dialog End*/}

            {/* Change Processing Status Modal */}
            <Dialog
              visible={approveConfirmModal}
              style={{ width: '950px' }}
              header="Add Processing Data"
              footer={
                <>
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={hideApproveConfirmModal}
                  />
                  <Button
                    label="Approve"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={addProcessData}
                    loading={loading}
                  />
                </>
              }
              onHide={hideApproveConfirmModal}
            >
              <br />
              <div className="formgrid grid">
                <div className="field col">
                  <div className="grid">
                    <div className="field col-12 md:col-4">
                      <p> <b>Crop</b> : {!window.cn(viewProductData.crop) ? viewProductData.crop_obj?.name : ""}</p>
                    </div>
                    <div className="field col-12 md:col-4">
                      <p> <b>Variety</b> : {!window.cn(viewProductData.variety) ? viewProductData.variety_obj?.name : ""}</p>
                    </div>
                    <div className="field col-12 md:col-4">
                      <p> <b>Lot No.</b> : {!window.cn(viewProductData.lot_no) ? viewProductData.lot_no : ""}</p>
                    </div>
                    <div className="field col-12 md:col-4">
                      <p> <b>Unprocessed Qty.</b> : {!window.cn(viewProductData.crop_qnty) ? viewProductData.crop_qnty + " " + viewProductData.weight_in : ""}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col-3">
                  <label htmlFor="processed_qnty">Processed Quantity *</label>
                  <InputText
                    keyfilter="num"
                    value={!window.cn(processData.processed_qnty) ? processData.processed_qnty : ""}
                    name="processed_qnty"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'processed_qnty')}
                    className={errors['processed_qnty'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['processed_qnty']}</small>
                </div>
                <div className="field col-3">
                  <label htmlFor="wight">Weight *</label>
                  <InputText
                    value={!window.cn(viewProductData.weight_in) ? viewProductData.weight_in : ""}
                    name="weight"
                    autoComplete="off"
                    disabled
                    readOnly
                  />
                </div>
                <div className="field col-3">
                  <label htmlFor="remenant">Remenant *</label>
                  <InputText
                    keyfilter="num"
                    value={!window.cn(processData.remenant) ? processData.remenant : ""}
                    name="remenant"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'remenant')}
                    className={errors['remenant'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['remenant']}</small>
                </div>
                <div className="field col-3">
                  <label htmlFor="losses">Processed Loss *</label>
                  <InputText
                    keyfilter="num"
                    value={!window.cn(processData.losses) ? processData.losses : ""}
                    name="losses"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'losses')}
                    className={errors['losses'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['losses']}</small>
                </div>
              </div>

            </Dialog>
            {/* Change Processing Status Modal End */}

            {/* Split Dialog start*/}
            <Dialog
              visible={mergeDialog}
              style={{ width: '850px' }}
              header="Merge Data"
              modal
              footer={
                <>
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={hideMergeDialog}
                  />
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={onMergeData}
                    loading={loading}
                  />
                </>
              }
              onHide={hideMergeDialog}
            >

              <div className="p-fluid formgrid grid mb-5">
                <div className="col-12 md:col-4 lg:col-6">
                  <RadioButton inputId="New" name="type" value="New" onChange={(e) => setMergeType(e.value)} checked={mergeType === 'New'} />
                  <label htmlFor="New" className="ml-2">Merge To New Lot No.</label>
                </div>
                <div className="col-12 md:col-4 lg:col-6">
                  <RadioButton inputId="Existing" name="type" value="Existing" onChange={(e) => setMergeType(e.value)} checked={mergeType === 'Existing'} />
                  <label htmlFor="Existing" className="ml-2">Merge to Existing Lot No.</label>
                </div>
              </div>
              <hr></hr>
              <div className="p-fluid formgrid grid mt-2">
                <div className="field col-12 md:col-4">
                  <label htmlFor="crop">Select Crop *</label>
                  <Dropdown
                    filter
                    value={selectedCropId}
                    onChange={(e) => handleSelectedCrop(e.value)}
                    options={cropData}
                    optionLabel="name"
                    name="crop"
                    placeholder="Select Crop"
                    className={errors['crop'] && 'p-invalid'}
                  ></Dropdown>
                  <small className="p-invalid-txt">{errors['crop']}</small>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="variety">Select Variety *</label>
                  <Dropdown
                    filter
                    value={selectedVarietyId}
                    onChange={(e) => handleSelectedVariety(e.value)}
                    options={varietyData}
                    optionLabel="name"
                    name="variety"
                    placeholder="Select Variety"
                    className={errors['variety'] && 'p-invalid'}
                  ></Dropdown>
                  <small className="p-invalid-txt">{errors['variety']}</small>
                </div>

                {!window.cn(mergeType) && mergeType == 'New' ?
                  <>
                    {!window.cn(selectedCropId) && !window.cn(selectedVarietyId) && lotNoList.length <= 1 ?
                      <>
                        <div className="field col-12"> <span className="delete-dialog-note">Not Enough Lot No Available For Merge. Please Select Another Crop & Variety.</span></div>
                      </> : <>

                        <div className="field col-12 md:col-4">
                          <label htmlFor="lot_no">Select Lot No. To Merge *</label>
                          <MultiSelect
                            value={selectedLotNo}
                            options={lotNoList}
                            onChange={(e) => setSelectedLotNo(e.value)}
                            // display="chip"
                            className={errors['lot_no_to_merge'] && 'p-invalid'}
                            placeholder='Select Lot No'
                            filter={true}
                          />
                          <small className="p-invalid-txt">{errors['lot_no_to_merge']} </small>
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="lot_no">New Lot No. *</label>
                          <InputText
                            value={newLotNo}
                            name="lot_no"
                            autoComplete="off"
                            onChange={(e) => setNewLotNo(e.target.value)}
                            placeholder='Enter New Lot No'
                            className={errors['lot_no'] && 'p-invalid'}
                          />
                          <small className="p-invalid-txt">{errors['lot_no']} </small>
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="lot_no">Class *</label>
                          <Dropdown
                            id="crop_division"
                            filter
                            value={cropDivisionDropdownItem}
                            onChange={(e) => setCropDropdownItem(e.value)}
                            options={cropDivisionDropdownItems}
                            optionLabel="name"
                            placeholder="Select One"
                            className={errors['crop_division'] && 'p-invalid'}
                          ></Dropdown>
                          <small className="p-invalid-txt">
                            {errors['crop_division']}
                          </small>
                        </div>

                      </>}
                  </>
                  : <>
                    {!window.cn(selectedCropId) && !window.cn(selectedVarietyId) && lotNoList.length <= 1 ?
                      <>
                        <div className="field col-12"> <span className="delete-dialog-note">Not Enough Lot No Available For Merge. Please Select Another Crop & Variety.</span></div>
                      </> : <>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="lot_no">Existing Lot No. *</label>
                          <Dropdown
                            value={existingLotNo}
                            options={lotNoList}
                            onChange={(e) => onHandleExistingLotNo(e.value)}
                            className={errors['merge_to_existing_lot_no'] && 'p-invalid'}
                            placeholder='Select Lot No'
                            filter={true}
                          />
                          <small className="p-invalid-txt">{errors['merge_to_existing_lot_no']} </small>
                        </div>

                        {lotNoDetailsArr.map((item: any, index: number) => (
                          <>
                            <div className="field col-12 md:col-4">
                              <label htmlFor="lot_no">Select Lot No. To Merge*</label>
                              <Dropdown
                                value={lotNoDetailsArr[index]["lot_no"]}
                                options={mergeLotNoList}
                                onChange={(e) => onHandleChange(e.value, index, 'lot_no')}
                                className={errors['lot_no'] && 'p-invalid'}
                                placeholder='Select Lot No'
                                filter={true}
                              />
                              <small className="p-invalid-txt">{errors['lot_no']} </small>
                            </div>
                            <div className="field col-12 md:col-4">
                              <label htmlFor="crop_qnty">Quantity</label>
                              <InputText
                                keyfilter="num"
                                defaultValue={!window.cn(lotNoDetailsArr[index]["crop_qnty"]) ? lotNoDetailsArr[index]["crop_qnty"] : ""}
                                value={!window.cn(lotNoDetailsArr[index]["crop_qnty"]) ? lotNoDetailsArr[index]["crop_qnty"] : ""}
                                placeholder='Enetr Quantity'
                                name="crop_qnty"
                                autoComplete="off"
                                className={errors['crop_qnty'] && 'p-invalid'}
                                onChange={(e) => onHandleChange(e, index, "crop_qnty")}
                              />
                              <small className="p-invalid-txt">{errors['crop_qnty']} </small>
                            </div>

                            {index > 0 ?
                              <div className="field col-12 md:col-4 mt-3">
                                <Button
                                  icon="pi pi-trash"
                                  className="p-button-rounded p-button-danger mt-2"
                                  onClick={() => deleteAddMore(index)} />
                              </div>
                              : <div className="field col-12 md:col-4 "></div>
                            }
                            <hr />
                          </>
                        ))}

                        <div className="field col-12 md:col-12">
                          <Button
                            style={{ width: 'fit-content' }}
                            label="Add More"
                            icon="pi pi-plus"
                            className="p-button"
                            onClick={handleAddMore}
                          />
                        </div> </>}
                  </>}
              </div>
              {
                spinner && <div className="loading flex justify-content-center">
                  <ProgressSpinner />
                </div>
              }
            </Dialog>
            {/* Split Dialog End*/}

            {/* Split Dialog start*/}
            <Dialog
              visible={revertDialog}
              style={{ width: '850px' }}
              header="Revert Data"
              modal
              footer={
                <>
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={hideRevertDialog}
                  />
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={onRevertData}
                    loading={loading}
                  />
                </>
              }
              onHide={hideRevertDialog}
            >

              <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                  <label htmlFor="packing_size">Packing Size *</label>
                  <Dropdown
                    value={revertData["packing_size"]}
                    onChange={(e) => onRevertHandleChange(e.value, 'packing_size')}
                    options={packingSizeDropdown}
                    optionLabel="name"
                    name="packing_size"
                    placeholder="Select Packing Size"
                    className={errors['packing_size'] && 'p-invalid'}
                  ></Dropdown>
                  <small className="p-invalid-txt">{errors['packing_size']}</small>
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="crop">No Of Packets To Revert *</label>
                  <InputText
                    value={!window.cn(revertData.no_of_packets) ? revertData.no_of_packets : ""}
                    name="no_of_packets"
                    autoComplete="off"
                    onChange={(e) => onRevertHandleChange(e.target.value, "no_of_packets")}
                  />
                  <small className="p-invalid-txt">{errors['no_of_packets']}</small>
                </div>
              </div>

              {
                spinner && <div className="loading flex justify-content-center">
                  <ProgressSpinner />
                </div>
              }
            </Dialog>
            {/* Split Dialog End*/}
          </div>
        </div>
      </div >
    </>
  );
};